import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../shared/components/inputFields/input";
import { REPORT_MANAGEMENT } from "../reportsManagementContants";
import style from './editContentId.module.scss';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { editReportsContentId, getReportCategories, viewReportsContentId } from "../../../../redux/AurLaVieAdmin/reports/reports.action";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { useParams } from "react-router-dom";

const EditContentId = () => {

    const [formData, setFormData] = useState({});
    const axiosInstance = useAxiosInstance();
    const [toastList, setToastList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [reportDetails, setReportDetails] = useState();
    const [containers, setContainers] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();
    const inputFields = [
        {
            type: "dropdown",
            name: "reportContentId",
            placeholder: "Content Id",
            options: categories,
            label: "Content Id",
            width: "100%",
        },
        {
            type: "textarea",
            name: "reportContent",
            placeholder: "Report Content",
            label: "Report Content",
            width: "100%",
        },
    ];

    useEffect(() => {
        getReportCategory(axiosInstance);
    }, [axiosInstance])

    useEffect(() => {
        const params = {
            isAdmin: true
        }
        getReport(axiosInstance, params, id);
    }, [id])

    const getReportCategory = (axiosInstance) => {
        dispatch(getReportCategories(axiosInstance)).then((response) => {
            const updatedCategories = response.listingData.map(category => {
                return {
                    label: category?.categoryName,
                    value: category?._id
                }
            });
            setCategories(updatedCategories);
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    title: "Error Fetching Report Categories",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const getReport = (axiosInstance, params, reportId) => {
        dispatch(viewReportsContentId(axiosInstance, params, reportId)).then((response) => {
            setReportDetails(response?.data?.data);
            setFormData({ ...response, reportContentId: { label: 'Good Colours', value: response?.reportContentId } });
            console.log('re', response?.data?.data)
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    title: "Error Fetching Report Detail",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const updateReport = (axiosInstance, payload, params, contentId) => {
        dispatch(editReportsContentId(axiosInstance, payload, params, contentId)).then((response) => {
            console.log('res', response)
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    title: "Error Updating Report Content",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    const handleSubmit = (event) => { 
        event.preventDefault();
    };

    const handleFile = (url, subContainerIndex) => {
        // if (url === " ") {
        //     handleInputChange("", subContainerIndex, "img")
        // } else if (url && url.name) {
        //     uploadToS3(url, url.name, subContainerIndex, "container")
        // }
    };

    const addContainer = () => {
        setContainers([...containers, { heading: "" }]);
    };

    const removeSubContainer = (index, containerDetails) => {
        // let updatedContainers = JSON.parse(JSON.stringify([...containers]));
        // updatedContainers[containerDetails].images.splice(index, 1);
        // setContainers([...updatedContainers]);
    };

    return <div className={style.container}>
        <div className={style.wrapper}>
            <div className={style.back}>
                <Back title={REPORT_MANAGEMENT.EDIT_REPORT_CONTENT_ID.BACK.TITLE} description={REPORT_MANAGEMENT.EDIT_REPORT_CONTENT_ID.BACK.DESCRIPTION} />
            </div>
            <section className={style.content}>
                <div className={style.upload}>
                    <h3 className={style.heading}>Main Image</h3>
                    <ImageUpload
                        previewImage={reportDetails?.headImages ? reportDetails?.headImages[0] : null}
                        id="mainImage"
                    // handleFile={(url) => handleMainImage(url, "mainImage")}
                    />
                </div>
                <div className={style.form}>
                    <form onSubmit={handleSubmit}>
                        {inputFields.map((inputField, index) => (
                            <div
                                key={index}
                                className={style.input_field}
                                style={{ width: inputField.width }}
                            >
                                <Input
                                    type={inputField.type}
                                    label={inputField.label}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}
                                    options={inputField.options}
                                    onChange={(value) =>
                                        handleInputChange(inputField.name, value)
                                    }
                                />
                            </div>
                        ))}
                        <div className={style.subcontent_divider}>
                            <div className={style.label_container}>
                                <h3 className={style.label}>Example Images</h3>
                                {reportDetails?.exampleImages.map((data, innerIndex) => (
                                    <div key={innerIndex} className={style.content}>
                                        <ImageUpload containerIndex={innerIndex} id={`${innerIndex}`} handleFile={(data) => handleFile(data, innerIndex)} previewImage={data} height={'80px'} width={'80px'} />
                                        <Input
                                            type="text"
                                            name="imageName"
                                            placeholder="Image Name"
                                            label="Image Name"
                                            value={data.imgName}
                                            onChange={(value) => handleInputChange(value, innerIndex, "subcontainer")}
                                            mainWidth={'100%'}
                                        />
                                        <Button icon={"/icons/delete.svg"} onClick={() => removeSubContainer(innerIndex)} />
                                    </div>
                                ))}
                                <div className={style.add_buttons}>
                                    <Button
                                        text={"+ Add Fields"}
                                        buttonType={`${buttonStyle.button_secondary}`}
                                        marginTop={0}
                                        onClick={() => addContainer()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={style.action_button}>
                            <Button
                                text={REPORT_MANAGEMENT.EDIT_REPORT_CONTENT_ID.BUTTONS.TITLE}
                                buttonType={`${buttonStyle.button_primary}`}
                            />
                        </div>
                    </form>
                </div>
            </section>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={3500}
        />
    </div>
}

export default EditContentId;