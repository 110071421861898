import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import { NOTIFICATION_MANAGEMENT } from "../notification.constant";
import style from "./viewNotification.module.scss";
import { useEffect, useState } from "react";
import {
  deleteNotification,
  viewNotification,
} from "../../../../redux/AurLaVieAdmin/manageNotifications/manageNotification.actions";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import useExtractSubstring from "../../../../hooks/useExtractSubstring";

const ViewNotification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [toastList, setToastList] = useState([]);
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [notificationDetails, setNotificationDetails] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const routeSplit = location?.pathname.split("/");
  const urlToken = routeSplit[routeSplit.length - 1];

  const isFengShuiSetting = location.pathname === NOTIFICATION_MANAGEMENT.URL_PATH.VIEW_NOTIFICATION.replace(/:id/g, urlToken)

  useEffect(() => {
    viewNotificationDetails(axiosInstance, urlToken);
  }, []);

  const editNotification = () => {
    isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.EDIT_NOTIFICATION.replace(/:id/g, urlToken)) :
    navigate(`/notifications/editNotification/${urlToken}`);
  };

  const deleteNotificationModalHandler = () => {
    setShowDeleteModal(true);
  };

  const viewNotificationDetails = (axiosInstance, id) => {
    dispatch(viewNotification(axiosInstance, { id }))
      .then((response) => {
        setNotificationDetails(response);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            title: "Error fetching notification details",
            description: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleDeleteNotification = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
    } else {
      const data = {
        ids: [urlToken],
      };
      dispatch(deleteNotification(axiosInstance, data))
        .then((res) => {
          setToastList([
            {
              id: 0,
              title: "Notification Deleted Successfully",
              description: res.message,
              icon: "success",
            },
          ]);
          setTimeout(() => {
            isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI) : navigate("/notifications")
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              title: "Delete Notification Error",
              description: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={NOTIFICATION_MANAGEMENT.DELETE_NOTIFICATION.TITLE}
            description={
              NOTIFICATION_MANAGEMENT.DELETE_NOTIFICATION.DESCRIPTION
            }
            onButtonClick={handleDeleteNotification}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={NOTIFICATION_MANAGEMENT.VIEW_NOTIFICATION.BACK.TITLE}
            description={
              NOTIFICATION_MANAGEMENT.VIEW_NOTIFICATION.BACK.DESCRIPTION
            }
          />
        </div>
        <div className={style.heading}>
          <Heading
            title={useExtractSubstring(notificationDetails?._id)}
            description={
              NOTIFICATION_MANAGEMENT.VIEW_NOTIFICATION.HEADING.DESCRIPTION
            }
          />
          <div className={style.action_buttons}>
            <Button
              text={
                NOTIFICATION_MANAGEMENT.VIEW_NOTIFICATION.BUTTONS.EDIT_TITLE
              }
              icon={"/icons/edit.svg"}
              onClick={editNotification}
            />
            <Button
              text={NOTIFICATION_MANAGEMENT.VIEW_NOTIFICATION.BUTTONS.DELETE}
              icon={"/icons/delete.svg"}
              onClick={deleteNotificationModalHandler}
            />
          </div>
        </div>
        <div className={style.details}>
          <div className={style.profile_details}>
            <div className={style.left}>
              {/* <div className={style.row}> */}
              <div className={style.sub_detail}>
                <label className={style.label}>Title</label>
                <p className={style.value}>{notificationDetails?.title}</p>
              </div>
              {notificationDetails?.scheduleStatus && (
                <div className={style.sub_detail}>
                  <label className={style.label}>Triggered Date</label>
                  <p className={style.value}>
                    {moment(notificationDetails?.scheduleDate).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                </div>
              )}

              <div className={style.sub_detail}>
                <label className={style.label}>Created On</label>
                <p className={style.value}>
                  {moment(notificationDetails?.created_at).format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <div className={style.right}>
              {/* <div className={style.row}> */}
              <div className={style.sub_detail}>
                <label className={style.label}>Content</label>
                <p className={style.value}>
                  {notificationDetails?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default ViewNotification;
