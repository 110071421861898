import Back from "../../../../../../shared/components/backButton/backButton"
import Button from "../../../../../../shared/components/button/button";
import Heading from "../../../../../../shared/components/heading/heading";
import { ADVICE_MANAGEMENT } from "../../../advice.constant";
import style from './viewContent.module.scss';
import { useEffect, useState } from "react";
import { viewContentIdData } from "../../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import useAxiosInstance from "../../../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Input from "../../../../../../shared/components/inputFields/input";
import Chip from "../../../../../../shared/components/chips/chips";
import Editor from '../../../../../../shared/components/editor/editor'

const ViewContent = () => {
    const { id } = useParams();
    const location = useLocation();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [contentDetails, setContentDetails] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = {
            isAdmin: true,
            id: id
        }
        viewContentIdDetails(axiosInstance, queryParams);
    }, []);

    const deleteContent = () => {

    }

    const editContent = () => {
        navigate(`/adviceManagement/content/edit/${id}`);
    }

    const viewContentIdDetails = (axiosInstance, params) => {
        dispatch(viewContentIdData(axiosInstance, params)).then((response) => {
            setContentDetails(response);
        }).catch((error) => {
            console.log('err', error);
            // setToastList([
            //     {
            //         id: 0,
            //         title: "Error adding user",
            //         description: error.response.data.message,
            //         icon: "error",
            //     },
            // ]);
        });
    }

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.back}>
                    <Back title={ADVICE_MANAGEMENT.VIEW_CONTENT_DATA.BACK.DESCRIPTION} description={ADVICE_MANAGEMENT.VIEW_CONTENT_DATA.BACK.TITLE} />
                </div>
                <div className={style.heading}>
                    <Heading title={contentDetails?.adviceId?.adviceName} description={ADVICE_MANAGEMENT.VIEW_CONTENT_DATA.HEADING.DESCRIPTION} />
                    <div className={style.action_buttons}>
                        <Button text={ADVICE_MANAGEMENT.VIEW_CONTENT_DATA.BUTTONS.EDIT.TITLE} icon={'/icons/edit.svg'} onClick={editContent} />
                        {/* <Button text={ADVICE_MANAGEMENT.VIEW_CONTENT_DATA.BUTTONS.DELETE.TITLE} icon={'/icons/delete.svg'} onClick={deleteContent} /> */}
                    </div>
                </div>
                <label className={style.top_label}>Content</label>
                <div className={style.details}>
                    <div className={style.profile_image}>
                        <img src={contentDetails && contentDetails?.mainImgPath?.length && contentDetails?.mainImgPath !== "string" ? contentDetails?.mainImgPath : "/icons/user_blue.svg"} alt="profile" />
                    </div>
                    <div className={style.profile_details}>
                        <div className={style.column}>
                            {contentDetails && contentDetails?.parentAdviceId
                                ?.adviceLabel !== "food" ? <div style={{ padding: `0 30px` }}>
                                <label className={style.top_label}>{contentDetails ? contentDetails?.title : null}</label>
                                <div className={style.top_profile_image}>
                                    <img src={contentDetails && contentDetails?.headImgPath?.length && contentDetails?.headImgPath[0] !== "string" ? contentDetails?.headImgPath[0] : "/icons/user_blue.svg"} alt="profile" />
                                </div>
                            </div> : null}

                            <Editor borderRequired={true} readyOnly={true} content={contentDetails?.content} />
                            {/* <p dangerouslySetInnerHTML={{ __html: contentDetails?.content }}></p> */}


                            {
                                contentDetails && contentDetails?.parentAdviceId
                                    ?.adviceLabel !== "perfumes" ?
                                    contentDetails &&
                                        contentDetails?.subHeadings?.length ?
                                        contentDetails?.subHeadings?.map((content, index) => {
                                            return (
                                                <div className={style.row}>
                                                    <label className={style.content_heading}>{content.heading}</label>
                                                    <div className={style.subcontent_divider}>
                                                        <div className={style.label_container}>
                                                            {content?.images?.length ? content?.images.map(data => (
                                                                <div className={style.content}>
                                                                    <div className={style.image_container}>
                                                                        <img src={data.imgPath} alt={data.imgPath} />
                                                                    </div>
                                                                    <label className={style.top_label}>{data.imgName}</label>
                                                                </div>
                                                            )) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    : <div className={style.form_container}>
                                        <label className={style.top_label}>{contentDetails ? contentDetails?.tagHeadings[0]?.heading : null}</label>
                                        <div className={style.chips}>
                                            <Chip data={contentDetails && contentDetails.tagHeadings[0] &&
                                                contentDetails.tagHeadings[0].tags.map(element => {
                                                    return { label: element }
                                                })} showRemove={false} />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewContent;