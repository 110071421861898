import React from "react";
import style from './button.module.scss';

const Button = ({ icon, text, type, buttonType, marginTop, disabled, onClick }) => {
  return (
    <button type={type} className={`${style.button} + ${buttonType}`} disabled={disabled} onClick={onClick} style={{marginTop: marginTop}}>
      {icon ? <div className={`${style.icon_container}`} style={{borderRight: text !== undefined ? '2px solid #CCD0D8' : '', padding: text !== undefined ? '12px' : ''}}>
          <img src={icon} alt=""/>
      </div> : null}
      <span className={`${style.title}`} style={{padding: text !== undefined ? '12px' : ''}}>{text}</span>
    </button>
  );
};

export default Button;
