import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import style from "./editor.module.scss";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

const Link = Quill.import('formats/link');
Link.sanitize = function (url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`
  }
  return url;
}

Quill.register(Link, true);


const Editor = ({ content, readyOnly, setContent, borderRequired }) => {
  return (
    <div className={style.editor}>
      <ReactQuill
        className={!readyOnly ? null : borderRequired ? "border-editor" : "blurred-editor"}
        readOnly={readyOnly}
        value={content}
        onChange={setContent}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            [{ header: "1" }, { header: "2" }],
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [
              { list: "ordered" },
              { list: "bullet" },
            ],
            [{ "link": "link" }, "image",],
            [{ 'color': [] }],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
          },
          imageResize: {
            parchment: Quill.import("parchment"),
            modules: ["Resize", "DisplaySize"]
          }
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "video",
          "align",
          "color"
        ]}
      />
    </div>
  );
};

export default Editor;
