import React from 'react';
import style from './toggleButton.module.scss';

const ToggleButton = ({preLable, postLable, isChecked, handleToggle}) => {

    return <>
        <div className={style.toggle_container}>
            <span className={style.label}>{preLable}</span>
            <label className={style.switch}>
                <input type="checkbox" checked={isChecked} onChange={handleToggle}></input>
                <span className={`${style.slider} ${style.round}`}></span>
            </label>
            <span className={style.label}>{postLable}</span>
        </div>
    </>
}

export default ToggleButton;
