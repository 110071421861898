import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import AddBooks from "../../booksManagement/addBooks/addBooks";
import Heading from "../../../../shared/components/heading/heading";
import Button from "../../../../shared/components/button/button";
import Input from "../../../../shared/components/inputFields/input";
import Table from "../../../../shared/components/table/table";
import style from './getHelp.module.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { SETTINGS_MANAGEMENT } from "../settings.constant";
import { deleteHelp, getHelps } from "../../../../redux/AurLaVieAdmin/getHelp/getHelp.action";
import moment from "moment";
import AddGetHelp from "./addGetHelp/addGetHelp";
import ViewHelp from "./viewGetHelp/viewGetHelp";
import EditGetHelp from "./editGetHelp/editGetHelp";
import Delete from "../../../../shared/components/delete/delete";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";


const GetHelp = () => {

    const [toastList, setToastList] = useState([]);
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [helps, setHelps] = useState([]);
    // const navigate = useNavigate();
    const [showAddBook, setShowAddBook] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    // const [languageId, setLanguageId] = useState('');
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [help, setHelp] = useState('');
    const [totalItem, setTotalItem] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    // const [id, setId] = useState();
    const currentDeleteRef = useRef(null);

    const [ids, setIds] = useState(null);
    const languageData = useSelector(languageState);
    const location = useLocation()
    const isFengShuiSetting = location.pathname === SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI
    const appId = isFengShuiSetting ? "641bdbd22b1809d1b4c83976" : localStorage.getItem("appId")

    const params = {
        page,
        limit: pageSize,
        isAdmin: true,
        appId: appId,
        languageId: languageData?.languageId,
    }

    useEffect(() => {
        if (languageData?.languageId) {
            currentDeleteRef.current = null
            getHelp(axiosInstance, params);
        }
    }, [axiosInstance, page, languageData, isFengShuiSetting]);

    useEffect(() => {
        if (languageData.languageId) {
            getHelp(axiosInstance, params);
        }
    }, [languageData]);

    useEffect(() => {
        let timerId;
        const fetchResults = async () => {
            try {
                if (languageData?.languageId) {
                    const params = {
                        page,
                        limit: pageSize,
                        isAdmin: true,
                        appId: appId,
                        languageId: languageData?.languageId,
                        keyword: searchTerm
                    }
                    getHelp(axiosInstance, params);
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (searchTerm || searchTerm === "") {
            timerId = setTimeout(() => {
                fetchResults();
            }, 500);
        } else {
            if (languageData?.languageId) {
                const params = {
                    page,
                    limit: pageSize,
                    isAdmin: true,
                    languageId: languageData?.languageId,
                }
                getHelp(axiosInstance, params);
            }
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [searchTerm, languageData]);

    const handlePage = (page) => {
        setPage(page);
    };

    const getHelp = (axiosInstance, params) => {
        dispatch(getHelps(axiosInstance, params)).then((response) => {

            const updatedHelps = response?.listingData?.map((help, index) => {
                return {
                    id: help?._id,
                    question: help?.question,
                    answer: help?.answer,
                    lastUpdatedOn: moment(help?.modified_at).format("MM/DD/YYYY"),
                }
            })
            setHelps(updatedHelps);
            setTotalItem(response?.meta?.total);
        }).catch((err) => {
            setToastList([
                {
                    id: 0,
                    title: "Fething Helps Failed",
                    description: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const deleteGetHelp = (axiosInstance, data) => {
        dispatch(deleteHelp(axiosInstance, data)).then((response) => {
            setShowDelete(false);

            setIds(null);
            currentDeleteRef.current = null;
            const params = {
                isAdmin: true,
                appId: appId,
                languageId: languageData?.languageId,
            }
            getHelp(axiosInstance, params);
            setToastList([
                {
                    id: 0,
                    title: "Help Deleted",
                    description: response?.message,
                    icon: "success",
                },
            ]);
        }).catch((err) => {
            setToastList([
                {
                    id: 0,
                    title: "Deleting Helps Failed",
                    description: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const addBook = () => {
        setShowAddBook(true);
    }

    const closeModal = () => {
        setShowAddBook(false);
    }

    const loadView = (row) => {
        setHelp(row);
        setIsView(true);
    }

    const onEdit = (row) => {
        setHelp(row);
        setIsEdit(true);
    }

    const onSearch = (event) => {
        setSearchTerm(event);
    }

    const editSuccess = (status) => {
        if (status) {
            setIsEdit(false);
            const params = {
                isAdmin: true,
                appId: appId,
                languageId: languageData?.languageId,
            }
            getHelp(axiosInstance, params);
        }
    }

    const onDelete = (row) => {
        currentDeleteRef.current = [row.id];
        setShowDelete(true);
    }

    const onButtonClick = (clickedButton) => {

        if (clickedButton === 'Cancel') {
            setShowDelete(false);
        } else if (clickedButton === 'Ok') {
            const data = currentDeleteRef.current?.length
                ? { ids: currentDeleteRef.current }
                : { ids };
            deleteGetHelp(axiosInstance, data);
        }
    }

    const handleClose = () => {
        closeModal();
        getHelp(axiosInstance, params);
    }

    const deleteSuccess = () => {
        setIsView(false);
        getHelp(axiosInstance, params);
    }

    const handleMultiple = (event) => {
        setIds(event);
    };

    const deletemany = () => {
        if (ids.length) {
            setShowDelete(true);
        }
    };


    return <section className={style.container}>
        <div className={style.wrapper}>
            <CenterModal isOpen={showAddBook} onClose={closeModal}>
                <AddGetHelp isFengShuiSetting={isFengShuiSetting} setToastList={setToastList} appId={appId} languageId={languageData?.languageId} resetText={!showAddBook} handleClose={handleClose} />
            </CenterModal>
            <CenterModal isOpen={showDelete} onClose={() => { setShowDelete(false) }} minWidth={'300px'}>
                <Delete title={'Delete Help'} description={'Are you sure you want to delete this Help?'} onButtonClick={onButtonClick} />
            </CenterModal>
            {isView ? <ViewHelp isFengShuiSetting={isFengShuiSetting} details={help} deleteSuccess={deleteSuccess} /> : isEdit ? <EditGetHelp isFengShuiSetting={isFengShuiSetting} details={help} editSuccess={editSuccess} /> : <>
                <section className={style.title_filters}>
                    <Heading title={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.HEADING.TITLE : SETTINGS_MANAGEMENT.GET_HELP.HEADING.TITLE} description={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.HEADING.DESCRIPTION : SETTINGS_MANAGEMENT.GET_HELP.HEADING.DESCRIPTION} fontSize={'30px'} />
                    <div className={style.filters}>
                        <Button icon={'/icons/add.svg'} text={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.BUTTONS.TITLE : SETTINGS_MANAGEMENT.GET_HELP.BUTTONS.TITLE} onClick={addBook} />
                        <Input type={'search'} placeholder={isFengShuiSetting ? SETTINGS_MANAGEMENT.FAQ.FILTERS.SEARCH.PLACEHOLDER : SETTINGS_MANAGEMENT.GET_HELP.FILTERS.SEARCH.PLACEHOLDER} onChange={onSearch} value={searchTerm} />
                        <Button icon={'/icons/delete.svg'} onClick={deletemany} />
                    </div>
                </section>
                <section className={style.listing}>
                    <Table handleMultiple={handleMultiple} data={helps} onView={loadView} onEdit={onEdit} onDelete={onDelete} marginTop={'200px'} noDataText='No Get Help Found' totalItem={totalItem}
                        handlePage={handlePage}
                        pageNumber={page} />
                </section>
            </>}
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
        />
    </section>
}

export default GetHelp;