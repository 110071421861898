import { useEffect, useState } from 'react';
import Input from '../../../../../shared/components/inputFields/input';
import { Born, Gender, SexualPreference, TextAlign, Twins } from '../../../../../shared/globalConstants/constants';
import style from './editAstrologyProfile.module.scss';
import buttonStyle from '../../../../../shared/components/button/button.module.scss';
import Button from '../../../../../shared/components/button/button';
import { USER_MANAGEMENT } from '../../userManagementConstants';
import dayjs from 'dayjs';
import ImageUpload from '../../../../../shared/components/imageUpload/imageUpload';
import Heading from '../../../../../shared/components/heading/heading';
import { updateAstrologerProfile } from '../../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions';
import useAxiosInstance from '../../../../../hooks/useAxiosInstance';
import { useDispatch } from 'react-redux';
import Toaster from '../../../../../components/Global/Toaster/Toaster.component';
import AWS from "aws-sdk";
import { isLoading } from '../../../../../redux/progressLoader/progressLoader.actions';
import { generateUUID } from '../../../../../hooks/uniqueIdGenerator';

const EditAstrologyProfile = (props) => {

    const { astrologyProfile, userId, closed } = props;

    const inputFields = [
        {
            type: "text",
            name: "name",
            placeholder: 'Name',
            label: 'Name',
            required: true,
            width: '100%'
        },
        {
            type: "mui-date-picker",
            name: "dob",
            placeholder: 'Date of Birth',
            label: 'Date of Birth',
            width: '100%'
        },
        {
            type: "radio",
            name: "gender",
            placeholder: 'Gender',
            label: 'Gender',
            options: Gender,
            width: '50%'
        },
        {
            type: "radio",
            name: "twins",
            placeholder: 'Are you twins?',
            label: 'Are you twins?',
            options: Twins,
            width: '50%'
        },
        {
            type: "radio",
            name: "born",
            placeholder: 'Born',
            label: 'Born',
            options: Born,
            width: '50%'
        },
        {
            type: "radio",
            name: "sexualPreference",
            placeholder: 'Sexual Preference',
            label: 'Sexual Preference',
            options: SexualPreference,
            width: '50%'
        },
    ];
    const [formData, setFormData] = useState({});
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [userImage, setUserImage] = useState("");

    useEffect(() => {
        const profileDetails = {
            name: astrologyProfile?.name,
            dob: astrologyProfile?.dob ? dayjs(astrologyProfile.dob) : null,
            gender: astrologyProfile?.gender?.toLowerCase(),
            twins: astrologyProfile?.twins,
            born: astrologyProfile?.born,
            sexualPreference: astrologyProfile?.sexualPreference?.toLowerCase(),
            profilePic: astrologyProfile?.profilePic,
            userId: astrologyProfile?.userId,
            appId: localStorage.getItem("appId")
        }
        setFormData(profileDetails);
    }, [astrologyProfile])

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    const handleInputBlur = () => {

    }

    AWS.config.update({
        accessKeyId: "AKIA24FEBSB6D3SDB5XV",
        secretAccessKey: "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",
    });

    const myBucket = new AWS.S3({
        params: { Bucket: "aurlavie" },
        region: "us-east-1",
    });

    const uploadToS3 = async (file, name) => {
        dispatch(isLoading(true))
        const id = generateUUID();
        const fileKey = `user-profile-${id}`;
        const params = {
            Body: file,
            Bucket: "aurlavie",
            Key: fileKey,
        };
        try {
            const upload = await myBucket.upload(params).promise();
            if (upload) {
                dispatch(isLoading(false));
                updateAstrologyProfile(axiosInstance, formData, params, astrologyProfile?._id, upload.Location);
            }
        } catch (error) {
            console.log(error);
            setToastList([
                {
                    id: 0,
                    title: "Error",
                    description: "Error while uploading the resume.",
                    icon: "error",
                },
            ]);
            dispatch(isLoading(false));
        }
    };

    const handleFile = (url) => {
        if (url) {
            setUserImage(url);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const params = {
            isAdmin: true
        }
        if (userImage.name) {
            uploadToS3(userImage, userImage.name);
        } else {
            updateAstrologyProfile(axiosInstance, formData, params, astrologyProfile?._id);
        }
    }

    const updateAstrologyProfile = (axiosInstance, details, params, userId, image) => {
        if (image) {
            details["profilePic"] = image;
        } else {
            details["profilePic"] = null;
        }
        dispatch(updateAstrologerProfile(axiosInstance, details, params, userId)).then((response) => {
            setToastList([
                {
                    id: 0,
                    title: "Astrology Profile Updated",
                    description: "Astrology Profile Updated Successfully",
                    icon: "success",
                },
            ]);
            setTimeout(() => {
                closed('modal');
                setFormData({});
            }, 1500);
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    title: "Error fetching user details",
                    description: error?.response?.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    return <div className={style.container}>
        <div className={style.wrapper}>
            <Heading title={USER_MANAGEMENT.UPDATE_ASTROLOGY_PROFILE.HEADING.TITLE} description={USER_MANAGEMENT.UPDATE_ASTROLOGY_PROFILE.HEADING.DESCRIPTION} fontSize={'1.4rem'} textAlign={TextAlign.CENTER} />
            <div className={style.form_container}>
                <div className={style.upload}>
                    <ImageUpload handleFile={handleFile} title={USER_MANAGEMENT.EDIT_USER.UPLOAD.TITLE} previewImage={astrologyProfile?.profilePic} />
                </div>
                <form onSubmit={handleSubmit}>
                    {
                        inputFields.map((inputField, index) => {
                            return <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                <Input
                                    disablePast={false}
                                    type={inputField.type}
                                    name={inputField.name}
                                    label={inputField.label}
                                    value={formData[inputField.name]}
                                    placeholder={inputField.placeholder}
                                    options={inputField.options}
                                    required={inputField.required}
                                    onChange={(value) => handleInputChange(inputField.name, value)}
                                    handleInputBlur={handleInputBlur}
                                    disableFuture={true}
                                    views={["year", "day", "hours", "minutes"]}
                                    openTo={"year"}
                                />
                            </div>
                        })
                    }
                    <div className={style.action_button}>
                        <Button text={USER_MANAGEMENT.UPDATE_ASTROLOGY_PROFILE.BUTTONS.TITLE} buttonType={`${buttonStyle.button_primary}`} />
                    </div>
                </form>
            </div>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
        />
    </div>
}

export default EditAstrologyProfile;