import { axiosInstance } from "../../../constants/axiosInstance";
import { endpoints } from "../../../constants/url.endpoint";
import { manageUsersActions } from "./manageUsers.reducer";

const {
  manageUsersSuccess,
  addUserSuccess,
  editUserSuccess,
  viewUserSuccess,
  deleteUserSuccess,
  updateAstrologerProfileSuccess,
  resetManageUsersData,
  resetUserDetail,
  viewUsersDetailsSuccess,
} = manageUsersActions;

export const fetchUserListing =
  (axiosInstance, payload, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.userListing,
        method: "POST",
        params: params,
        data: payload,
      })
        .then((res) => {
          dispatch(manageUsersSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetUserDetail());
          reject(err);
        });
    });
  };

export const addUser =
  (axiosInstance, userDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addUser,
        method: "POST",
        data: userDetails,
        params: params,
      })
        .then((response) => {
          dispatch(addUserSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const editUser =
  (axiosInstance, userDetails, userId, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.editUser + userId,
        method: "POST",
        data: userDetails,
        params: params,
      })
        .then((response) => {
          dispatch(editUserSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const viewUser = (axiosInstance, email, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.viewUser,
      method: "POST",
      params: params,
      data: {
        email: email,
      },
    })
      .then((response) => {
        dispatch(viewUserSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetUserDetail());
        reject(error);
      });
  });
};

export const deleteAppUser = (axiosInstance, details, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.deleteUser,
      method: "POST",
      params: params,
      data: details
    })
      .then((response) => {
        dispatch(deleteUserSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetUserDetail());
        reject(error);
      });
  });
};

export const updateAstrologerProfile = (axiosInstance, details, params, userId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.updateAstrologerProfile + userId,
      method: "POST",
      params: params,
      data: details
    })
      .then((response) => {
        dispatch(updateAstrologerProfileSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetUserDetail());
        reject(error);
      });
  });
};
