import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
};
const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState,
  reducers: {
    manageUsersSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    editUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewUsersDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateAstrologerProfileSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetUserDetail: (state, action) => {
      state.data = null;
    },
    resetManageUsersData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: manageUsersActions,
  reducer: manageUsersReducer,
  name: manageUsers,
} = manageUsersSlice;
