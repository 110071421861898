import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import style from './sidebar.module.scss';
import { SETTINGS_MANAGEMENT } from "../../../pages/admin/settings/settings.constant";
import { NOTIFICATION_MANAGEMENT } from "../../../pages/admin/notification/notification.constant";

const SidebarComponent = () => {
  const location = useLocation();
  const [subscribed, setSubscribed] = useState(false);
  const [userType, setUserType] = useState("");
  const [sideBarList, setSideBarList] = useState([]);
  const [newActiveLink, setNewActiveLink] = useState(null);
  const [newInnerActiveLink, setNewInnerActiveLink] = useState(null);

  const sidebarItems = [
    {
      name: "User Management",
      route: "/userManagement",
      icon: 'user',
    },
    {
      appName: "Feng Shui",
      icon: 'fs',
      subItems: [
        {
          name: "Tips Management",
          route: "/tipsManagement",
          icon: 'tips',
        },
        {
          name: "Reports",
          route: "/fengshui/reportsManagement",
          icon: 'reports',
        },
        {
          name: "Notification",
          route: NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI,
          icon: 'notification',
        },
        {
          name: "Settings",
          route: SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI,
          icon: 'settings',
        },
      ]
    },
    {
      appName: "Aur La Vie",
      icon: 'alv',
      subItems: [
        {
          name: "Books Management",
          route: "/booksManagement",
          icon: 'books',
        },
        {
          name: "Advice Management",
          route: "/adviceManagement",
          icon: 'advice',
        },
        {
          name: "Notification",
          route: "/notifications",
          icon: 'notification',
        },
        {
          name: "Settings",
          route: "/settings",
          icon: 'settings',
        },
      ]
    },
    {
      name: "Sub Admin",
      route: "/subAdmin",
      icon: 'subAdmin',
    },
    {
      name: "Profile Management",
      route: "/profileManagement",
      icon: 'profile',
    },
  ]
  const commonSideBarList = [
    {
      name: "User Management",
      route: "/userManagement",
      icon: 'user',
    },
    {
      name: "Tips Management",
      route: "/tipsManagement",
      icon: 'tips',
    },
    {
      name: "FS Setting",
      route: SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI,
      icon: 'settings',
    },
    {
      name: "FS Notification",
      route: NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI,
      icon: 'notification',
    },
    {
      name: "Books Management",
      route: "/booksManagement",
      icon: 'books',
    },
    {
      name: "Notification",
      route: "/notifications",
      icon: 'notification',
    },
    {
      name: "Sub Admin",
      route: "/subAdmin",
      icon: 'subAdmin',
    },
    {
      name: "Settings",
      route: "/settings",
      icon: 'settings',
    },
    {
      name: "Advice Management",
      route: "/adviceManagement",
      icon: 'advice',
    },
    {
      name: "Reports",
      route: "/fengshui/reportsManagement",
      icon: 'reports',
    },
    {
      name: "Profile Management",
      route: "/profileManagement",
      icon: 'subAdmin',
    },
  ];

  useEffect(() => {
    let newSidebarValues = [];
    switch (userType?.toLowerCase()) {
      case "aptillio-admin":
        newSidebarValues = [];
        break;
      default:
        break;
    }
    sidebarItems.unshift(...newSidebarValues);
    setSideBarList(sidebarItems);
  }, [userType]);

  const collapse = (index) => {
    setSideBarList(prevState =>
      prevState.map((item, i) =>
        i === index ? { ...item, collapsed: !item.collapsed } : item
      )
    );
  }

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {sideBarList?.map((sidebar, i) => {
          if (
            location?.pathname === "/subscriptionPlans" &&
            userType === "org-admin" &&
            !subscribed
          ) {
            return (
              <NavLink
                onClick={(e) => e.preventDefault()}
                end={sidebar.route === "/"}
                key={i}
                to={sidebar.route}
                exact={"/"}
                activeclassname={style.active}
                className={({ isActive }) => (isActive ? `${style.active}` : `${style.inactive}`)}
              >
                {sidebar.icon}
                <p className={style.label}>{sidebar.name}</p>
              </NavLink>
            );
          }

          return (
            <>
              {!sidebar.hasOwnProperty('appName') ?
                <NavLink
                  end={sidebar.route === "/"}
                  key={i}
                  to={sidebar.route}
                  exact={"/"}
                  isActive={(match, location, i) => {
                    match && setNewActiveLink(i);
                    setNewActiveLink(match);
                    return match;
                  }}
                  // activeclassname={style.active}
                  className={({ isActive }) => (isActive ? `${style.active} ${style.link}` : `${style.inactive} ${style.link}`)}
                >
                  {location.pathname.includes(sidebar.route)
                    ? <img className={style.icon} src={'/sidebar/' + `${sidebar.icon}` + '_white.svg'} style={{ height: "20px", width: "25px" }} />
                    : <img className={style.icon} src={'/sidebar/' + `${sidebar.icon}` + '_grey.svg'} style={{ height: "20px", width: "25px" }} />}
                  <p className={style.label}>{sidebar.name}</p>
                </NavLink>
                :
                <div className={style.appLevel}>
                  <div className={((location.pathname.includes('fengshui') && sidebar.appName === 'Feng Shui') || (location.pathname.includes('aurlavie') && sidebar.appName === 'Aur La Vie')) ? `${style.activeApp}` : `${style.app}`}>
                    {((location.pathname.includes('fengshui') && sidebar.appName === 'Feng Shui') || (location.pathname.includes('aurlavie') && sidebar.appName === 'Aur La Vie'))
                      ? <img className={style.icon} src={'/sidebar/' + `${sidebar.icon}` + '_blue.svg'} style={{ height: "20px", width: "25px" }} />
                      : <img className={style.icon} src={'/sidebar/' + `${sidebar.icon}` + '_grey.svg'} style={{ height: "20px", width: "25px" }} />}
                    <p className={style.label}>{sidebar.appName}</p>
                    <img src="/icons/arrow_bottom_blue.svg" alt="down-arrow" className={sidebar.collapsed ? style.rotateArrow : style.arrow} onClick={() => collapse(i)}/>
                  </div>
                  {!sidebar.collapsed && (
                  <div className={style.subItemContainer}>
                    {
                      sidebar.subItems.map((subItem, index) => {
                        return <NavLink
                          end={subItem.route === "/"}
                          key={index}
                          to={subItem.route}
                          exact={"/"}
                          isActive={(match, location, i) => {
                            match && setNewInnerActiveLink(i);
                            setNewInnerActiveLink(match);
                            return match;
                          }}
                          activeclassname={style.active}
                          className={({ isActive }) => (isActive ? `${style.active} ${style.innerLink}` : `${style.inactive} ${style.innerLink}`)}
                        >
                          <div className={style.subItem}>
                            {location.pathname.includes(subItem.route)
                              ? <img className={style.icon} src={'/sidebar/' + `${subItem.icon}` + '_white.svg'} style={{ height: "20px", width: "25px" }} />
                              : <img className={style.icon} src={'/sidebar/' + `${subItem.icon}` + '_grey.svg'} style={{ height: "20px", width: "25px" }} />}
                            <p className={style.label}>{subItem.name}</p>
                          </div>
                        </NavLink>
                      })}
                  </div>
                  )}
                </div>
              }
            </>
          );
        })}
      </div>
    </div >
  );
};

export default SidebarComponent;
