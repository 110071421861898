import { endpoints } from "../../constants/url.endpoint";
import { utilitiesAction } from "./utility.reducer";

const {
    getLanguagesSuccess,
    resetUtilitiesData
} = utilitiesAction;

export const getLanguages = (axiosInstance, params) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.getLanguages,
                method: "GET",
                params: params,
            })
                .then((response) => {
                    dispatch(getLanguagesSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.error('Error in Get Languages', error)
                    dispatch(resetUtilitiesData());
                    reject(error);
                });
        });
    };