import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
};
const manageAdvicesSlice = createSlice({
  name: "manageAdvices",
  initialState,
  reducers: {
    advicesListingSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    contentIdsListingSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addAdviceSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewAdviceSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateAdviceSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewContentIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateContentIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteAdviceSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetAdviceDetail: (state, action) => {
      state.data = null;
    },
    resetManageAdvicesData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
    resetManageContentIdsData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: manageAdvicesActions,
  reducer: manageAdvicesReducer,
  name: manageUsers,
} = manageAdvicesSlice;
