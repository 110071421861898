import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import style from './ProfileManagement.module.scss';
import Heading from "../../../shared/components/heading/heading";
import { PROFILE_MANAGEMENT } from "./profileManagement.constant";
import Button from "../../../shared/components/button/button";
import TitleAndDetail from "../../../components/FengShui/TitleAndDetail/TitleAndDetail";

const ProfileManagement = () => {
    const navigate = useNavigate()
    const user = useSelector(store => store.user.data)
    console.log(user)

    const onEdit = () => navigate(`/profileManagement/edit`)

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.heading}>
                    <Heading title={PROFILE_MANAGEMENT.HEADING.TITLE} description={PROFILE_MANAGEMENT.HEADING.DESCRIPTION} icon="/icons/user_settings.png" />
                    <div className={style.action_buttons}>
                        <Button icon={'/icons/edit.svg'} text={PROFILE_MANAGEMENT.EDIT_PROFILE.TITLE} onClick={onEdit} />
                    </div>
                </div>
                <div className={style.details}>
                    <div className={style.container_images}>
                        <div className={style.main_image_container}>
                            <img src={user?.profilePic || "/icons/user_blue.svg"} alt="Profile" />
                        </div>
                    </div>
                    <div className={style.box}>
                        <div className={style.box_left}>
                            <TitleAndDetail title="First Name" detail={user.name} />
                            <TitleAndDetail title="Email Id" detail={user.email} />
                        </div>
                        <div className={style.box_right}>   
                            <TitleAndDetail detail_style={{ fontSize: 24 }} title="Password" detail="*****************" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileManagement;