export const NOTIFICATION_MANAGEMENT = {
  HEADING: {
    TITLE: "Notification",
    DESCRIPTION: "",
  },
  FILTERS: {
    SEARCH: {
      PLACEHOLDER: "Search Notification",
    },
  },
  ADD_NOTIFICATION: {
    BACK: {
      TITLE: "Add Notification",
      DESCRIPTION: "Back to notification listing",
    },

    BUTTONS: {
      TITLE: "Send Notification",
    },

    SWITCH_LABEL: {
      TITLE: "Trigger Date & Time",
    },
  },
  UPDATE_NOTIFICATION: {
    BACK: {
      TITLE: "Edit Notification",
      DESCRIPTION: "Back to view notification",
    },

    BUTTONS: {
      TITLE: "Update Notification",
    },

    DATE_LABEL: {
      TITLE: "Triggered Date ",
    },
  },
  VIEW_NOTIFICATION: {
    BACK: {
      TITLE: "View Notification",
      DESCRIPTION: "Back to view notification listing",
    },
    HEADING: {
      DESCRIPTION: "",
    },
    BUTTONS: {
      EDIT_TITLE: "Edit Notification",
      DELETE: "Delete Notification",
    },
  },
  DELETE_NOTIFICATION: {
    TITLE: "Delete Notification",
    DESCRIPTION: "Are you sure you want to delete this notification?",
  },
  URL_PATH: {
    FENG_SHUI: "/FengShui/Notification",
    ADD_NOTIFICATION: "/FengShui/addNotification",
    EDIT_NOTIFICATION: "/FengShui/editNotification/:id",
    VIEW_NOTIFICATION: "/FengShui/viewNotification/:id"
  }
};
