import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import style from "./ChangePassword.module.scss";
import Heading from '../../../../shared/components/heading/heading';
import Input from '../../../../shared/components/inputFields/input';
import Button from '../../../../shared/components/button/button';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import { TextAlign } from '../../../../shared/globalConstants/constants';
import { ChangePasswordAction } from '../../../../redux/users/user.actions';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';

const ChangePassword = (props) => {
    const { isModalClosed, id, setToastList, closeModal } = props;
    // const [formErrors, setFormErrors] = useState({
    //     "Enter Current Password": "Please Enter Your Current Password.",
    //     "Enter New Password": "Please Enter Your New Password.",
    //     "Enter Confirm Password": "Please Enter Your Confirm Password.",
    // });

    const formFields = [
        {
            type: "Privacytext",
            name: "Enter Current Password",
            placeholder: "Enter Current Password",
            label: "Enter Current Password",
            width: "100%",
        },
        {
            type: "Privacytext",
            name: "Enter New Password",
            placeholder: "Enter New Password",
            label: "Enter New Password",
            width: "100%",
        },
        {
            type: "Privacytext",
            name: "Enter Confirm Password",
            placeholder: "Enter Confirm Password",
            label: "Enter Confirm Password",
            width: "100%",
        },
    ];
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [inputFields, setInputFields] = useState(formFields);
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        setInputFields(formFields);
        setFormData({})
        setIsButtonDisabled(true);
    }, [isModalClosed])

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    }

    useEffect(() => {
        if(formData?.["Enter Current Password"] !== "" 
            && formData?.["Enter Current Password"] !== undefined  
            && formData?.["Enter New Password"] !== "" 
            && formData?.["Enter New Password"] !== undefined 
            && formData?.["Enter Confirm Password"] !== ""
            && formData?.["Enter Confirm Password"] !== undefined) {
                setIsButtonDisabled(false)
            } else {
            setIsButtonDisabled(true)
        }
    }, [formData])

    const handleSubmit = (event) => {
        event.preventDefault();
        if(formData?.["Enter New Password"] === formData?.["Enter Confirm Password"]) {
            const passwordDetail = {
                "oldPassword": formData?.["Enter Current Password"],
                "newPassword": formData?.["Enter New Password"]
            }
    
            dispatch(ChangePasswordAction(axiosInstance, passwordDetail, id, {}))
            .then((res) => {
                console.log(res)
                setFormData({})
                setIsButtonDisabled(true);
                closeModal()
                setToastList([
                    {
                    id: 0,
                    title: "Password Changed Successfully",
                    description: res.message,
                    icon: "success",
                    },
                ])
            })
            .catch((err) => {
                console.log(err)
                setToastList([
                    {
                    id: 0,
                    title: "Change Password Failed",
                    description: err?.response?.data?.message,
                    icon: "error",
                    },
                ])
            })
        } else {
            setToastList([
                {
                id: 0,
                title: "Change Password Failed",
                description: "New and Confirm Password did not match.",
                icon: "error",
                },
            ])
        }
       
    }

    // const handleInputBlur = (e) => {
    //     const { name, value } = e.target;
    //     if (name === "Enter Current Password") {
    //         if (value === '') {
    //             setFormErrors({ ...formErrors, [name]: 'Please Enter Your Current Password.' });
    //             setIsButtonDisabled(true)
    //         } else if (value !== '' && value.length < 8) {
    //             setFormErrors({ ...formErrors, [name]: 'Current Password must be at least 8 characters.' });
    //             setIsButtonDisabled(true)
    //         } else {
    //             setFormErrors({ ...formErrors, [name]: '' });
    //         }
    //     } else if (name === "Enter New Password") {
    //         if (value === '') {
    //             setFormErrors({ ...formErrors, [name]: "Please Enter Your New Password." });
    //             setIsButtonDisabled(true)
    //             return;
    //         } else if (value !== '' && value.length < 8) {
    //             setFormErrors({ ...formErrors, [name]: 'New Password must be at least 8 characters.' });
    //             setIsButtonDisabled(true)
    //             return;
    //         } else {
    //             setFormErrors({ ...formErrors, [name]: '' });
    //         }
    //          if (formData?.["Enter New Password"] !== formData?.["Enter Confirm Password"]) {
    //             setFormErrors(old => ({ ...old, "Enter Confirm Password": 'Confirm Password must match with New Password.' }));
    //             setIsButtonDisabled(true)
    //         }
    //     } else if (name === "Enter Confirm Password") {
    //         if (value === '') {
    //             setFormErrors({ ...formErrors, [name]: "Please Enter Your Confirm Password." });
    //             setIsButtonDisabled(true)
    //         } else if (value !== '' && value.length < 8) {
    //             setFormErrors({ ...formErrors, [name]: 'Confirm Password must be at least 8 characters.' });
    //             setIsButtonDisabled(true)
    //         } else {
    //             setFormErrors({ ...formErrors, [name]: '' });
    //         } 
    //         if (formData?.["Enter New Password"] !== formData?.["Enter Confirm Password"]) {
    //             setFormErrors({ ...formErrors, [name]: 'Confirm Password must match with New Password.' });
    //             setIsButtonDisabled(true)
    //         }
    //     }


    //     // if (formData?.["Enter Current Password"] !== "" 
    //     //         && formData?.["Enter Current Password"] !== undefined 
    //     //         && value !== '' 
    //     //         && value.length >= 8
    //     //         && formData?.["Enter New Password"] !== "" 
    //     //         && formData?.["Enter New Password"] !== undefined 
    //     //         && formData?.["Enter Confirm Password"] !== ""
    //     //         && formData?.["Enter Confirm Password"] !== undefined) {
    //     //             setIsButtonDisabled(false)
    //     // }
    //     // console.log()
    // };

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <Heading title="Change Password" description="Lora ipsum" textAlign={TextAlign.CENTER} />
                <div className={style.form_container}>
                    <form onSubmit={handleSubmit}>
                        <div className={style.input_container}>
                            {inputFields.map((inputField, index) => (
                                <div key={index} className={style.input_field} style={{width: inputField.width}}>
                                    <Input
                                        type={inputField.type}
                                        label={inputField.label}
                                        name={inputField.name}
                                        value={formData[inputField.name]}
                                        placeholder={inputField.placeholder}
                                        onChange={(value) => handleInputChange(inputField.name, value)}
                                        options={inputField.options}
                                        // handleInputBlur={handleInputBlur}
                                    />
                                    {/* <div className={style.error}>{formErrors[inputField.name]}</div> */}
                                </div>
                            ))}
                        </div>
                        <div className={style.action_button}>
                            <Button type='submit' text="Submit" disabled={isButtonDisabled} buttonType={isButtonDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;