import { useEffect, useState } from "react";
import Back from "../../../../../shared/components/backButton/backButton";
import Button from "../../../../../shared/components/button/button";
import Delete from "../../../../../shared/components/delete/delete";
import Heading from "../../../../../shared/components/heading/heading";
import CenterModal from "../../../../../shared/components/modal/centeredModal/centeredModal";
import style from "./viewBookSection.module.scss";
import { BOOKS_MANAGEMENT } from "../../booksManagementConstants";
import Chip from "../../../../../shared/components/chips/chips";
import { useNavigate, useParams } from "react-router-dom";
import {
  viewBookSection,
  deleteBookSection,
} from "../../../../../redux/AurLaVieAdmin/books/books.action";
import { useDispatch } from "react-redux";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { axiosInstance } from "../../../../../constants/axiosInstance";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";

const ViewBookSection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toastList, setToastList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [sectionData, setSectionData] = useState(null);

  const [chips, setChips] = useState(null);
  const onButtonClick = (clickedButton) => {
    if (clickedButton === "Cancel") {
      setShowDelete(false);
    } else {
      dispatch(deleteBookSection(axiosInstance, id))
        .then((res) => {
          setToastList([
            {
              id: 0,
              title: "Section Deleted Successfully",
              description: res?.message,
              icon: "success",
            },
          ]);

          setTimeout(() => {
            navigate(-1);
          }, 700);
        })
        .catch((err) => {
          setToastList([
            {
              id: 0,
              title: "Deleting section Failed",
              description: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  useEffect(() => {
    handleViewBookSection(axiosInstance);
  }, [axiosInstance]);

  const handleViewBookSection = (axiosInstance) => {
    dispatch(isLoading(true))
    dispatch(viewBookSection(axiosInstance, id))
      .then((res) => {
        dispatch(isLoading(false))
        setChips(
          res?.keywords.map((element) => {
            return { label: element };
          })
        );
        setSectionData(res);
      })
      .catch((err) => {
        dispatch(isLoading(false))
        setToastList([
          {
            id: 0,
            title: "Fething section Failed",
            description: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const editSection = () => {
    navigate(`/booksManagement/editBookSection/${id}`);
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDelete}
          onClose={() => {
            setShowDelete(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={BOOKS_MANAGEMENT.DELETE_BOOK_SECTION.TITLE}
            description={BOOKS_MANAGEMENT.DELETE_BOOK_SECTION.DESCRIPTION}
            onButtonClick={onButtonClick}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={BOOKS_MANAGEMENT.VIEw_BOOK_SECTION.BACK.TITLE}
            description={BOOKS_MANAGEMENT.EDIT_BOOK.BACK.DESCRIPTION}
          />
        </div>
        <section className={style.title_filters}>
          <Heading
            title={sectionData?.sectionName}
            description={BOOKS_MANAGEMENT.EDIT_BOOK.HEADING.DESCRIPTION}
          />
          <div className={style.filters}>
            <Button
              icon={"/icons/edit.svg"}
              text={"Edit Section"}
              onClick={editSection}
            />
            <Button
              icon={"/icons/delete.svg"}
              text={"Delete Section"}
              onClick={() => {
                setShowDelete(true);
              }}
            />
          </div>
        </section>
        <section className={style.content}>
          <h3 className={style.heading}>Content</h3>
          <div className={style.content_wrapper}>
            <h4 className={style.heading}>{sectionData?.keywordsHeading}</h4>
            <div className={style.chips}>
              <Chip data={chips} showRemove={false} />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: sectionData?.description }}
            ></div>
          </div>
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </section>
  );
};

export default ViewBookSection;
