export const AppType = [
    {
        value: 'Aur-La-Vie',
        label: 'Aur La Vie'
    },
    {
        value: 'Feng-Shui',
        label: 'Feng shui'
    }
]

export const UserType = [
    {
        value: 1,
        label: 'Normal User'
    },
    {
        value: 2,
        label: 'Beta User'
    }
]

export const Twins = [
    {
        value: true,
        label: 'Yes'
    },
    {
        value: false,
        label: 'No'
    }
]

export const Gender = [
    {
        value: 'male',
        label: 'Male'
    },
    {
        value: 'female',
        label: 'Female'
    }
]

export const SexualPreference = [
    {
        value: 'straight',
        label: 'Straight'
    },
    {
        value: 'lgbtqia+',
        label: 'LGBTQIA+'
    }
]

export const Status = [
    {
        value: 'active',
        label: 'Active'
    },
    {
        value: 'inactive',
        label: 'Inactive'
    }
]

export const Born = [
    {
        value: 0,
        label: 'First Born'
    },
    {
        value: 1,
        label: 'Second Born'
    }
]

export const TextAlign = {
    CENTER: 'center',
    LEFT: 'left',
    RIGHT: 'right'
}

export const APP = {
    FENG_SHUI: [{ lable: 'Feng Shui', value: 'Feng-Shui' }],
    AUR_LA_VIE: [{ lable: 'Aur La Vie', value: 'Aur-La-Vie' }],
}
