import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import style from './ViewTips.module.scss';
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { TIPS } from "../Tips/tipsManagementConstant";
import Back from "../../../../shared/components/backButton/backButton";
import Heading from "../../../../shared/components/heading/heading";
import Button from "../../../../shared/components/button/button";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import TitleAndDetail from "../../../../components/FengShui/TitleAndDetail/TitleAndDetail";
import { Status } from "../../../../shared/globalConstants/constants";
import Input from "../../../../shared/components/inputFields/input";
import { deleteTip, viewTip } from "../../../../redux/FengShui/manageTips/manageTips.actions";
import useExtractSubstring from "../../../../hooks/useExtractSubstring";

const ViewTips = () => {
    const inputFields = [
        {
            type: "radio",
            name: "status",
            placeholder: 'Status',
            label: 'Status',
            options: Status,
            disabled: "true",
            width: '50%'
        },

    ];

    const location = useLocation();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false)
    const [formData, setFormData] = useState({
        'status': true
    })
    const { id } = useParams()
    const tip = useSelector(store => store?.manageTips?.data)

    useEffect(() => {
        const queryParams = {
            isAdmin: true,
        }
        viewTipDetail(axiosInstance, queryParams);
    }, []);

    const onDeleteTip = (type) => {
        if(type === 'Cancel') {
            setShowDelete(false)
        } else if (type === 'Ok') {
            dispatch(deleteTip(axiosInstance, {ids: [id]}, {})).then(res => {
                navigate(-1)
            }).catch(error => {
                console.log(error)
                setShowDelete(false)
                setToastList([{
                    id: 0,
                    title: "Error deleting Tip",
                    description: error.response.data.message,
                    icon: "error",
                }])
            })
        }
    }

    const editTip = () => navigate(`/tipsManagement/editTips/${id}`, { state: { id: id } })

    const viewTipDetail = (axiosInstance, params) => {
        dispatch(viewTip(axiosInstance, id, params)).then((response) => {
        }).catch((error) => {
            setToastList([
                {
                    id: 0,
                    title: "Error Fetching Tip",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <CenterModal
                    isOpen={showDelete}
                    onClose={() => {
                        setShowDelete(false)
                    }}
                    minWidth={"300px"}
                    >
                    <Delete
                        title={"Delete Tip"}
                        description={"Are you sure you want to delete this tip?"}
                        onButtonClick={onDeleteTip}
                    />
                </CenterModal>
                <div className={style.back}>
                    <Back title={TIPS.VIEW_TIPS.BACK.TITLE} description={TIPS.VIEW_TIPS.BACK.DESCRIPTION} />
                </div>
                <div className={style.heading}>
                    {/* <Heading title={userDetails?._id?.slice(0, 5)} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} /> */}
                    <Heading title={useExtractSubstring(tip?._id)} description={TIPS.VIEW_TIPS.HEADING.DESCRIPTION} />
                    <div className={style.action_buttons}>
                        <Button icon={'/icons/edit.svg'} text={"Edit Tip"} onClick={editTip} />
                        <Button icon={'/icons/delete.svg'} text={"Delete Tip"} onClick={() => setShowDelete(true)} />
                    </div>
                </div>
                <div className={style.details}>
                    <div className={style.container_images}>
                        <div className={style.main_image_container}>
                            <img src={tip?.imgPath ? tip?.imgPath : "/icons/user_blue.svg"} />
                        </div>
                        <div className={style.other_images_container}>
                            {tip?.subImages && tip.subImages.map(img => <img src={img ? img : "/icons/user_blue.svg"}  />)}
                        </div>
                    </div>
                    <div className={style.box}>
                        <div className={style.box_left}>
                            <TitleAndDetail title="Tips Name" detail={tip?.title} />
                            <TitleAndDetail title="Category" detail={tip?.categoryId?.categoryName} />
                            <TitleAndDetail title="Created On" detail={moment(tip?.created_at).format("MM / DD / YYYY")} />
                        </div>
                        <div className={style.box_right}>
                            <TitleAndDetail detail_style={{ fontSize: 14 }} title="Tip Content" detail={tip?.description} />
                            {inputFields.map((inputField, index) => (
                                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                    <Input
                                        type={inputField.type}
                                        name={inputField.name}
                                        label={inputField.label}
                                        value={tip?.status}
                                        placeholder={inputField.placeholder}
                                        options={inputField.options}
                                        required={inputField.required}
                                        onChange={() => {}}
                                        handleInputBlur={() => {}}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewTips;