import { Route, Routes } from "react-router-dom";
import { routeItems } from "./routing/routes";
import React, { Suspense } from "react";
import LoginPage from "./pages/Login/Login.page";
import HomePage from "./pages/Dashboard/home.page";
import PrivateRoute from "./routing/privateRoute";
import ForgetPasswordPage from "./pages/ForgetPassword/forgetPassword.page";
import ResetPasswordPage from "./pages/resetPassword/resetPassword.page";
import UserManagment from "./pages/admin/userManagement/userManagement";
import AddUser from "./pages/admin/userManagement/addUser/addUser";
import ViewUser from "./pages/admin/userManagement/viewUser/viewUser";
import EditUser from "./pages/admin/userManagement/editUser/editUser";
import RedirectAppPage from "./pages/redirectApp/redirectApp.page";
import BooksManagement from "./pages/admin/booksManagement/booksManagement";
import HomeComponent from "./components/Global/HomeLayout/HomeLayout.component";
import AddBooks from "./pages/admin/booksManagement/addBooks/addBooks";
import NotificationPage from "./pages/admin/notification/notification.page";
import AddNotificationPage from "./pages/admin/notification/addNotification/addNotification.page";
import EditNotificationPage from "./pages/admin/notification/editNotification/editNotification.page";
import ViewNotificationPage from "./pages/admin/notification/viewNotification/viewNotification.page";
import EditBook from "./pages/admin/booksManagement/editBook/editBook";
import ViewBookSection from "./pages/admin/booksManagement/bookSections/viewBookSection/viewBookSection";
import EditBookSection from "./pages/admin/booksManagement/bookSections/editBookSection/editBookSection";
import AddBookSection from "./pages/admin/booksManagement/bookSections/addBookSection/addBookSection";
import SettingsPage from "./pages/admin/settings/settings.page";
import PrivacyPolicy from "./pages/admin/settings/privacyPolicy/privacyPolicy";
import TermsOfService from "./pages/admin/settings/termsOfServices/termsOfServices";
import About from "./pages/admin/settings/about/about";
import GetHelp from "./pages/admin/settings/getHelp/getHelp";
import ViewHelp from "./pages/admin/settings/getHelp/viewGetHelp/viewGetHelp";
import EditGetHelp from "./pages/admin/settings/getHelp/editGetHelp/editGetHelp";

import AdviceManagement from "./pages/admin/adviceManagement/adviceManagement";
import ActivityCardsManagement from "./pages/admin/adviceManagement/activityCards/activityCardsManagement";

import CategoryAndTipsSwitchManagementPage from "./pages/admin/FengShui/CategoryAndTipsSwitchManagement/CategoryAndTipsSwitchManagement.page";
import AddTips from "./pages/admin/FengShui/AddTips/AddTips";
import ViewTips from "./pages/admin/FengShui/ViewTips/ViewTips";
import EditTips from "./pages/admin/FengShui/EditTips/EditTips";
import SubAdmin from "./pages/admin/subAdminUserRoles/subAdmin/subAdmin";
import UserRoles from "./pages/admin/subAdminUserRoles/userRoles/userRoles";
import SubAdminUserRoles from "./pages/admin/subAdminUserRoles/subAdminUserRoles";
import ViewActivityCards from "./pages/admin/adviceManagement/activityCards/viewActivityCards/viewActivityCard";
import EditActivityCards from "./pages/admin/adviceManagement/activityCards/editActivityCards/editActivityCards";
import ContentManagement from "./pages/admin/adviceManagement/activityCards/contentManagement/contentManagement";
import ViewContentData from "./pages/admin/adviceManagement/activityCards/contentManagement/viewContent/viewContent";
import EditContentData from "./pages/admin/adviceManagement/activityCards/contentManagement/editContent/editContent";
import AddEditSubAdmin from "./pages/admin/subAdminUserRoles/subAdmin/addEditSubAdmin/addEditSubAdmin";
import ViewSubAdmin from "./pages/admin/subAdminUserRoles/subAdmin/viewSubAdmin/viewSubAdmin";
import AddContentData from "./pages/admin/adviceManagement/activityCards/contentManagement/addContent/addContent";
import ViewCategory from "./pages/admin/FengShui/ViewCategory/ViewCategory";
import AddCategory from "./pages/admin/FengShui/AddCategory/AddCategory";
import EditCategory from "./pages/admin/FengShui/EditCategory/EditCategory";
import Reports from "./pages/admin/reportsManagement/reportsManagement";
import AddContentId from "./pages/admin/reportsManagement/addContentId/addContentId";
import ViewContentId from "./pages/admin/reportsManagement/viewContentId/viewContentId";
import ProfileManagement from "./pages/admin/profileManagement/ProfileManagement";
import EditProfileManagement from "./pages/admin/profileManagement/editProfileManagement/EditProfileManagement";
import EditContentId from "./pages/admin/reportsManagement/editContentId/editContentId";


const PageList = {
  LoginPage: <LoginPage />,
  HomePage: <HomePage />,
  ForgetPassword: <ForgetPasswordPage />,
  ResetPassword: <ResetPasswordPage />,
  UserManagement: <UserManagment />,
  FengShuiNotification: <NotificationPage />,
  FengShuiAddNotification: <AddNotificationPage />,
  FengShuiEditNotification: <EditNotificationPage />,
  FengShuiViewNotification: <ViewNotificationPage />,
  FengShuiSetting: <SettingsPage />,
  CategoryAndTipsSwitchManagement: <CategoryAndTipsSwitchManagementPage />,
  AddCategory: <AddCategory />,
  EditCategory: <EditCategory />,
  ViewCategory: <ViewCategory />,
  AddTips: <AddTips />,
  EditTips: <EditTips />,
  ViewTips: <ViewTips />,
  AddUser: <AddUser />,
  ViewUser: <ViewUser />,
  EditUser: <EditUser />,
  RedirectApp: <RedirectAppPage />,
  BooksManagement: <BooksManagement />,
  AddBooks: <AddBooks />,
  EditBooks: <EditBook />,
  ViewBookSection: <ViewBookSection />,
  EditBookSection: <EditBookSection />,
  AddBookSection: <AddBookSection />,
  Notification: <NotificationPage />,
  AddNotification: <AddNotificationPage />,
  EditNotification: <EditNotificationPage />,
  ViewNotification: <ViewNotificationPage />,
  Settings: <SettingsPage />,
  SubAdminUserRoles: <SubAdminUserRoles />,
  UserRoles: <UserRoles />,
  PrivacyPolicy: <PrivacyPolicy />,
  TermsOfService: <TermsOfService />,
  About: <About />,
  GetHelp: <GetHelp />,
  ViewHelp: <ViewHelp />,
  EditHelp: <EditGetHelp />,
  AdviceManagement: <AdviceManagement />,
  ContentManagement: <ContentManagement />,
  ActivityCards: <ActivityCardsManagement />,
  SubAdmin: <SubAdmin />,
  ViewActivityCards: <ViewActivityCards />,
  EditActivityCards: <EditActivityCards />,
  ViewContentData: <ViewContentData />,
  EditContentData: <EditContentData />,
  AddEditSubAdmin: <AddEditSubAdmin />,
  ViewSubAdmin: <ViewSubAdmin />,
  AddContentData: <AddContentData />,
  Reports: <Reports/>,
  AddContentId: <AddContentId/>,
  ViewContentId: <ViewContentId/>,
  EditContentId: <EditContentId />,
  ProfileManagement: <ProfileManagement />,
  EditProfileManagement: <EditProfileManagement />,
};

const App = () => {
  return (
    <Suspense>
      <Routes>
        {routeItems.map((item, i) => {
          if (!item.protected) {
            return (
              <Route
                key={item.component}
                path={item.route}
                element={PageList[item.component]}
              />
            );
          } else {
            return (
              <Route
                key={item.component}
                path={item.route}
                element={
                  <HomeComponent
                    childrenComponent={
                      <PrivateRoute
                        path={item.route}
                        children={PageList[item.component]}
                      />
                    }
                  />
                }
              />
            );
          }
        })}
      </Routes>
    </Suspense>
  );
};

export default App;
