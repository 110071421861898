import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import Input from "../../../../shared/components/inputFields/input";
import { TextAlign } from "../../../../shared/globalConstants/constants";
import { REPORT_MANAGEMENT } from "../reportsManagementContants";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import style from './addContentId.module.scss';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { addReportsContentId } from "../../../../redux/AurLaVieAdmin/reports/reports.action";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

const AddContentId = ({isClosed}) => {
    const inputFields = [
        {
            type: "text",
            name: "reportContentId",
            placeholder: "Content Id",
            label: "Content Id",
            width: "100%",
        },
    ];
    const [formData, setFormData] = useState({});
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [toastList, setToastList] = useState([]);
    const languageData = useSelector(languageState);

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const params = {
            isAdmin: true
        }
        formData["appId"] = localStorage.getItem("appId");
        formData["languageId"] = languageData?.languageId;
        addContentId(axiosInstance, formData, params);
    };

    const addContentId = (axiosInstance, payload, params) => {
        dispatch(addReportsContentId(axiosInstance, payload, params)).then((response) => {
            setToastList([
                {
                    id: 0,
                    title: "Created Content Id",
                    description: response.data.message,
                    icon: "success",
                },
            ]);
            setTimeout(() => {
                setFormData({});
                isClosed();
            }, 1000)
        }).catch((error) => {
            console.log('err', error.response.data.message);
            setToastList([
                {
                    id: 0,
                    title: "Error adding user",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    return <div className={style.container}>
        <div className={style.wrapper}>
            <div className={style.heading}>
                <Heading
                    title={REPORT_MANAGEMENT.ADD_REPORT_CONTENT_ID.HEADING.TITLE}
                    description={REPORT_MANAGEMENT.ADD_REPORT_CONTENT_ID.HEADING.DESCRIPTION}
                    textAlign={TextAlign.CENTER}
                />
            </div>
            <div className={style.form_container}>
                <form onSubmit={handleSubmit}>
                    {inputFields.map((inputField, index) => (
                        <div
                            key={index}
                            className={style.input_field}
                            style={{ width: inputField.width }}
                        >
                            <Input
                                type={inputField.type}
                                label={inputField.label}
                                value={formData[inputField.name]}
                                placeholder={inputField.placeholder}
                                onChange={(value) =>
                                    handleInputChange(inputField.name, value)
                                }
                            />
                        </div>
                    ))}
                    <div className={style.action_button}>
                        <Button
                            text={REPORT_MANAGEMENT.ADD_REPORT_CONTENT_ID.BUTTONS.TITLE}
                            buttonType={`${buttonStyle.button_primary}`}
                        />
                    </div>
                </form>
            </div>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={3500}
        />
    </div>
}

export default AddContentId;