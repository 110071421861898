import { useEffect, useState } from "react";
import Heading from "../../../shared/components/heading/heading";
import Table from "../../../shared/components/table/table";
import { USER_MANAGEMENT } from "./userManagementConstants";
import "./userManagement.scss";
import Input from "../../../shared/components/inputFields/input";
import Button from "../../../shared/components/button/button";
import CenterModal from "../../../shared/components/modal/centeredModal/centeredModal";
import SelectAppUserType from "./selectAppUserType/selectAppUserType";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserListing } from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import Toaster from "../../../components/Global/Toaster/Toaster.component";
import { getAllApps } from "../../../redux/AurLaVieAdmin/apps/getApps.action";

const UserManagment = () => {
  const [users, setUsers] = useState([]);
  const [selectedApplicationType, setSelectedApplicationType] = useState();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const params = {
      page,
      limit: pageSize,
      isAdmin: true,
    };
    const payload = {
      appId: selectedApplicationType ? selectedApplicationType.value : "",
    };
    getAppInfo(axiosInstance);
    getUsers(axiosInstance, payload, params);
  }, [page]);

  useEffect(() => {
    if (selectedApplicationType) {
      setPage(1);
      const params = {
        page: 1,
        limit: pageSize,
        isAdmin: true,
      };

      const payload = {
        appId: selectedApplicationType.value,
      };

      getUsers(axiosInstance, payload, params);
    }
  }, [selectedApplicationType]);
  useEffect(() => {
    setPage(1);
    let timerId;
    const fetchResults = async () => {
      try {
        const params = {
          page: 1,
          limit: pageSize,
          isAdmin: true,
        };
        const payload = {
          keyword: searchTerm,
        };
        getUsers(axiosInstance, payload, params);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const getAppInfo = (axiosInstance) => {
    dispatch(getAllApps(axiosInstance)).then((res) => {
      setOptions(
        res.map((element) => {
          return { label: element.appName.replaceAll('-', ' '), value: element._id };
        })
      );
    });
  };

  const getUsers = (axiosInstance, payload, params) => {
    dispatch(fetchUserListing(axiosInstance, payload, params))
      .then((response) => {
        const updatedUsers = response.data.data.listingData.map((user) => {
          return {
            id: user.data[0]?._id,
            // userId: user?._id.slice(-5),
            // image: user?.profilePic ? user?.profilePic : '/icons/user_blue.svg',
            // name: user?.name,
            emailId: user.data[0]?.email,
            app: user.data.length == 2 ? "Aur La Vie / Feng Shui" :
              user.data[0]?.appDetails?.appName.replaceAll("-", " "),
            // createdDate: moment(user?.created_at).format("MM/DD/YYYY"),
            userType: user.data[0]?.userTypeDetails?.typeName,
          };
        });
        setUsers(updatedUsers);
        setTotalItem(response?.data?.data?.meta?.total);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            title: "Fething Users Failed",
            description: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const onApplicationChange = (event) => {
    setSelectedApplicationType(event);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  const onEdit = (event) => {
    navigate(`/userManagement/editUser/${event.id}`, { state: { email: event.emailId } });
  };

  const onDelete = (event) => {
    navigate(`/userManagement/viewUser`, { state: { email: event.emailId } });
  };

  const onView = (event) => {
    navigate(`/userManagement/viewUser`, { state: { email: event.emailId } });
  };

  const addUser = () => {
    setShowAddUserModal(true);
    setIsModalClosed(false);
  };

  const selectedRow = (row) => {
    navigate(`/userManagement/viewUser`, { state: { email: row.emailId } });
  }

  const deleteUser = (event) => { };

  const closeModal = () => {
    setShowAddUserModal(false);
    setIsModalClosed(true);
  };

  return (
    <section className="container">
      <div className="wrapper">
        <CenterModal isOpen={showAddUserModal} onClose={closeModal}>
          <SelectAppUserType isModalClosed={isModalClosed} />
        </CenterModal>
        <section className="title_filters">
          <Heading
            title={USER_MANAGEMENT.HEADING.TITLE}
            description={USER_MANAGEMENT.HEADING.DESCRIPTION}
            icon="/icons/user_settings.png"
          />
          <div className="filters">
            <Input
              type={"dropdown"}
              placeholder={USER_MANAGEMENT.FILTERS.APP_TYPE.PLACEHOLDER}
              value={selectedApplicationType}
              options={options}
              width={"180px"}
              onChange={onApplicationChange}
            />
            <Button
              icon={"/icons/add.svg"}
              text={"Add User"}
              onClick={addUser}
            />
            <Input
              type={"search"}
              placeholder={USER_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              onChange={onSearch}
              width={'220px'}
            />
            {/* <Button icon={"/icons/delete.svg"} onClick={deleteUser} /> */}
          </div>
        </section>
        <section className="listing">
          <Table
            deleteNotRequired={true}
            data={users}
            onView={(event) => onView(event)}
            onEdit={onEdit}
            onDelete={onDelete}
            totalItem={totalItem}
            handlePage={handlePage}
            selectedRow={selectedRow}
            pageNumber={page}
            noDataText={'No Users Found'}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default UserManagment;
