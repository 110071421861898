import { useEffect, useState } from 'react';
import Back from '../../../../shared/components/backButton/backButton';
import Button from '../../../../shared/components/button/button';
import Heading from '../../../../shared/components/heading/heading';
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload';
import Input from '../../../../shared/components/inputFields/input';
import { USER_MANAGEMENT } from '../userManagementConstants';
import style from './editUser.module.scss';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import { Status } from '../../../../shared/globalConstants/constants';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { useDispatch } from 'react-redux';
import { editUser, viewUser } from '../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserTypes } from '../../../../redux/AurLaVieAdmin/userType/userType.action';
import ToggleButton from '../../../../shared/components/toggleButton/toggleButton';
import AWS from "aws-sdk";
import { isLoading } from '../../../../redux/progressLoader/progressLoader.actions';
import Toaster from '../../../../components/Global/Toaster/Toaster.component';
import dayjs from 'dayjs';
import moment from 'moment';
import CenterModal from '../../../../shared/components/modal/centeredModal/centeredModal';
import EditAstrologyProfile from './editAstrologyProfile/editAstrologyProfile';
import { generateUUID } from '../../../../hooks/uniqueIdGenerator';

const EditUser = () => {

    const inputFields = [
        {
            type: "text",
            name: "name",
            placeholder: 'Name',
            label: 'Name',
            required: true,
            width: '50%'
        },
        {
            type: "text",
            name: "email",
            placeholder: 'Email ID',
            label: 'Email ID',
            required: true,
            width: '50%'
        },
        {
            type: "password",
            name: "password",
            placeholder: 'Password',
            label: 'Password',
            width: '50%'
        },
        {
            type: "password",
            name: "passwordConfirm",
            placeholder: 'Confirm Password',
            label: 'Confirm Password',
            width: '50%'
        },
        {
            type: "radio",
            name: "status",
            placeholder: 'Status',
            label: 'Status',
            options: Status,
            width: '50%'
        },

    ];
    const [formData, setFormData] = useState({});
    const [isVip, setIsVip] = useState(false);
    const [userDetails, setUserDetails] = useState();
    const [userProfiles, setUserProfiles] = useState();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const userId = useParams();
    const location = useLocation();
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [userImage, setUserImage] = useState("");
    const [appId, setAppId] = useState('');
    const navigate = useNavigate();
    const [showEditAstrologyModal, setShowEditAstrologyModal] = useState(false);
    const [astrologyProfile, setAstrologyProfile] = useState();

    const handleInputChange = (fieldName, fieldValue) => {
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    useEffect(() => {
        const selectedAppId = localStorage.getItem('appId');
        setAppId(selectedAppId);
    }, [appId])

    useEffect(() => {
        const queryParams = {
            isAdmin: true
        }
        viewUserDetails(axiosInstance, location.state.email, queryParams);
    }, []);

    useEffect(() => {
        const hasEmptyRequiredFields = inputFields
            .filter(field => field.required)
            .some(field => !formData[field.name]);
        setIsSubmitDisabled(hasEmptyRequiredFields);
    }, [inputFields, formData]);

    const viewUserDetails = (axiosInstance, email, params) => {
        dispatch(viewUser(axiosInstance, email, params)).then((details) => {

            let response;

            if (details.length === 2) {
                response = details[0]._id === userId.id ? details[0] : details[1];
            } else {
                response = details[0]
            }

            const res = {
                name: response.name,
                email: response.email,
                dob: response?.dob ? dayjs(response.dob) : null,
                gender: response.gender,
                twins: response.twins,
                born: response.born,
                sexualPreference: response.sexualPreference,
                status: response.status,
                profilePic: response.profilePic,
                appId: response.appId
            }
            setIsVip(response.isVip);
            setUserProfiles(response?.userProfileData);
            setUserDetails(res);
            setFormData(res)
        }).catch((error) => {
            console.log('err', error);
            setToastList([
                {
                    id: 0,
                    title: "Error fetching user details",
                    description: error?.response?.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const editUserDetails = (axiosInstance, userDetails, userId, params) => {
        dispatch(editUser(axiosInstance, userDetails, userId, params)).then((response) => {
            setToastList([
                {
                    id: 0,
                    title: "User Updated",
                    description: response.message,
                    icon: "success",
                },
            ]);
            setTimeout(() => {
                navigate('/userManagement');
            }, 1000)
        }).catch((error) => {
            console.log('err', error.response.data.message);
            setToastList([
                {
                    id: 0,
                    title: "Error editing user",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const getUserType = (axiosInstance, appId, imageUrl) => {
        dispatch(getUserTypes(axiosInstance, appId)).then((response) => {
            if (response?._id) {

                formData['appId'] = appId;
                formData['userTypeId'] = response?._id;
                const userDetails = {
                    "email": formData.email,
                    "password": formData.password,
                    "passwordConfirm": formData.passwordConfirm,
                    "name": formData.name,
                    "appId": formData.appId,
                    "twins": formData.twins,
                    "gender": formData.gender,
                    "sexualPreference": formData.sexualPreference,
                    "born": formData.born,
                    "isVip": isVip,
                    "status": formData.status,
                    "userTypeId": response?._id
                }

                if (imageUrl) {
                    userDetails['profilePic'] = imageUrl;
                } else {
                    userDetails.profilePic = null;
                }


                const params = {
                    isAdmin: true,
                }
                editUserDetails(axiosInstance, userDetails, userId.id, params);
            }
        }).catch((error) => {
            setToastList([
                {
                    id: 0,
                    title: "Fetching User Type Failed",
                    description: error?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (userImage.name) {
            uploadToS3(userImage, userImage.name);
        } else {
            getUserType(axiosInstance, appId);
        }
    }

    const handleToggle = () => {
        setIsVip(!isVip);
    }

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        const emailRegex = /^[a-z0-9.]+@[a-z]+\.[a-z]{1,3}$/;
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
        const nameRegex = /^[a-zA-Z ]+$/;
        if (name === 'name') {
            if (value === '') {
                setFormErrors({ ...formErrors, name: 'Name is required' });
            } else if (value.length > 50) {
                setFormErrors({ ...formErrors, name: 'Maximum 50 characters allowed' });
            } else if (!nameRegex.test(value)) {
                setFormErrors({ ...formErrors, name: 'Only alphabets allowed' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'email') {
            if (value === '') {
                setFormErrors({ ...formErrors, email: 'Email is required' });
            } else if (!emailRegex.test(value)) {
                setFormErrors({ ...formErrors, email: 'Invalid Email' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'password') {
            if (value !== '' && value.length < 8) {
                setFormErrors({ ...formErrors, password: 'Password must be at least 8 characters' });
            } else if (value === '') {
                setFormErrors({ ...formErrors, password: 'Password is required' });
            } else if (!passwordRegex.test(value)) {
                setFormErrors({ ...formErrors, password: 'Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'passwordConfirm') {
            if (value !== '' && value.length < 8) {
                setFormErrors({ ...formErrors, passwordConfirm: 'Password must be at least 8 characters' });
            } else if (value === '') {
                setFormErrors({ ...formErrors, passwordConfirm: 'Password is required' });
            } else if (!passwordRegex.test(value)) {
                setFormErrors({ ...formErrors, passwordConfirm: 'Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else {
            setFormErrors({ ...formErrors, [name]: '' })
        }
    };

    AWS.config.update({
        accessKeyId: "AKIA24FEBSB6D3SDB5XV",
        secretAccessKey: "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",
    });

    const myBucket = new AWS.S3({
        params: { Bucket: "aurlavie" },
        region: "us-east-1",
    });

    const uploadToS3 = async (file, name) => {
        dispatch(isLoading(true))
        const id = generateUUID();
        const fileKey = `user-${id}`;
        const params = {
            Body: file,
            Bucket: "aurlavie",
            Key: fileKey,
        };
        try {
            const upload = await myBucket.upload(params).promise();
            if (upload) {
                dispatch(isLoading(false));
                getUserType(axiosInstance, appId, upload.Location);
            }
        } catch (error) {
            console.log(error);
            setToastList([
                {
                    id: 0,
                    title: "Error",
                    description: "Error while uploading the resume.",
                    icon: "error",
                },
            ]);
            dispatch(isLoading(false));
        }
    };

    const handleFile = (url) => {
        if (url) {
            setUserImage(url);
        }
    };

    const updateAstrologyProfile = (details) => {
        setShowEditAstrologyModal(true);
        setAstrologyProfile(details);
    }

    const closeModal = (closedFrom) => {
        if (closedFrom === 'modal') {
            const queryParams = {
                isAdmin: true
            }
            viewUserDetails(axiosInstance, location.state.email, queryParams);
        }
        setShowEditAstrologyModal(false);
    }

    return (
        <div className={style.container}>
            <CenterModal isOpen={showEditAstrologyModal} onClose={closeModal}>
                <EditAstrologyProfile astrologyProfile={astrologyProfile} userId={userId.id} closed={closeModal} />
            </CenterModal>
            <div className={style.wrapper}>
                <div className={style.back}>
                    <Back title={USER_MANAGEMENT.EDIT_USER.BACK.TITLE} description={USER_MANAGEMENT.EDIT_USER.BACK.DESCRIPTION} />
                </div>
                <div className={style.form_container}>
                    <div className={style.upload}>
                        <ImageUpload id="edit_user_upload" handleFile={handleFile} title={USER_MANAGEMENT.EDIT_USER.UPLOAD.TITLE} previewImage={userDetails?.profilePic} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <Heading title={USER_MANAGEMENT.EDIT_USER.SUB_HEADING.TITLE} description={USER_MANAGEMENT.EDIT_USER.SUB_HEADING.DESCRIPTION} fontSize={'1.4rem'} />
                        <hr className={style.divider} />
                        <div className={style.form_container}>
                            {inputFields.map((inputField, index) => (
                                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                    <Input
                                        disablePast={false}
                                        type={inputField.type}
                                        name={inputField.name}
                                        label={inputField.label}
                                        value={formData[inputField.name]}
                                        placeholder={inputField.placeholder}
                                        options={inputField.options}
                                        required={inputField.required}
                                        onChange={(value) => handleInputChange(inputField.name, value)}
                                        handleInputBlur={handleInputBlur}
                                        disableFuture={true}
                                        views={["year", "day", "hours", "minutes"]}
                                        openTo={"year"}
                                    />
                                    <div className={style.error}>{formErrors[inputField.name]}</div>
                                </div>
                            ))}
                            {userDetails?.appId?.appName === "Aur-La-Vie" && <div className={style.input_field} style={{ width: '50%' }}>
                                <label className={style.label}>VIP User</label>
                                <ToggleButton preLable={'Inactive'} postLable={'Active'} isChecked={isVip || userDetails?.isVip} handleToggle={handleToggle} />
                            </div>}
                            <div className={style.action_button}>
                                <Button disabled={isSubmitDisabled || Object.values(formErrors).some(error => Boolean(error))} text={USER_MANAGEMENT.EDIT_USER.BUTTONS.TITLE} buttonType={(isSubmitDisabled || Object.values(formErrors).some(error => Boolean(error))) ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} />
                            </div>
                            {userProfiles?.length > 0 && <div className={style.astrology_profiles}>
                                <div className={style.astrology_profiles_wrapper}>
                                    <Heading title={USER_MANAGEMENT.EDIT_USER.PROFILE_DETAILS.TITLE} description={USER_MANAGEMENT.EDIT_USER.PROFILE_DETAILS.DESCRIPTION} fontSize={'1.4rem'} />
                                    {
                                        userProfiles && userProfiles.length > 0 && userProfiles.map((profile, index) => {
                                            return <div key={index}>
                                                <hr className={style.divider} />
                                                <div className={style.action}>
                                                    <h3 className={style.label}>Astrology Profile {index + 1}</h3>
                                                    <img src='/icons/edit.svg' alt='edit' onClick={() => updateAstrologyProfile(profile)} />
                                                </div>
                                                <div className={style.astrologers}>
                                                    <div className={style.profile_image}>
                                                        <img src={profile?.profilePic ? profile?.profilePic : "/icons/user_blue.svg"} alt="profile" />
                                                    </div>
                                                    <div className={style.column}>
                                                        <div className={style.row}>
                                                            <div className={style.sub_detail}>
                                                                <label className={style.label}>Full Name</label>
                                                                <p className={style.value}>{profile?.name}</p>
                                                            </div>
                                                            <div className={style.sub_detail}>
                                                                <label className={style.label}>Date Of Birth</label>
                                                                <p className={style.value}>{moment.utc(profile?.dob).format('MM/DD/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className={style.row}>
                                                            <div className={style.sub_detail}>
                                                                <label className={style.label}>Birth Time</label>
                                                                <p className={style.value}>{moment(profile?.dob).format('h:mm A')}</p>
                                                            </div>
                                                            <div className={style.sub_detail}>
                                                                <label className={style.label}>Are You Twin</label>
                                                                <p className={style.value}>{profile?.twins ? 'Yes' : 'No'}</p>
                                                            </div>
                                                        </div>
                                                        <div className={style.row}>
                                                            <div className={style.sub_detail}>
                                                                <label className={style.label}>Gender</label>
                                                                <p className={style.value}>{profile?.gender}</p>
                                                            </div>
                                                            <div className={style.sub_detail}>
                                                                <label className={style.label}>Sexual Preference</label>
                                                                <p className={style.value}>{profile?.sexualPreference}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>}
                        </div>
                    </form>
                </div>
            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </div>
    )
}

export default EditUser;