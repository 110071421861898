import { useState, useEffect } from "react";

import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import { SETTINGS_MANAGEMENT } from "../settings.constant";
import style from "./termsOfServices.module.scss";
import Editor from "../../../../shared/components/editor/editor";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";

import {
  addContent,
  updateContent,
  viewContent,
} from "../../../../redux/AurLaVieAdmin/manageContent/manageContent.actions";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";
import { useLocation } from "react-router-dom";

const TermsOfService = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const isFengShuiSetting = location.pathname === SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI

  const axiosInstance = useAxiosInstance();

  const [edit, setEdit] = useState(false);

  const [content, setContent] = useState("");

  const [toastList, setToastList] = useState([]);

  const [contentId, setContentId] = useState(null);

  const languageData = useSelector(languageState);

  useEffect(() => {
    if (languageData?.languageId) {
      handleViewContent();
    }
  }, [axiosInstance, languageData, isFengShuiSetting]);

  const handleViewContent = () => {
    setEdit(false);
    const appId = isFengShuiSetting ? "641bdbd22b1809d1b4c83976" : localStorage.getItem("appId");
    const languageId = languageData?.languageId;
    const pageName = isFengShuiSetting ? "terms" : "tnc";

    const params = {
      appId,
      languageId,
      pageName,
    };

    dispatch(viewContent(axiosInstance, params))
      .then((response) => {
        setContentId(response?._id);
        setContent(response?.description);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            title: "Error fetching content ",
            description: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const onEdit = () => {
    setEdit(true);
  };

  const handleEdit = () => {
    const payload = {
      description: content,
    };

    const params = {
      id: contentId,
    };

    dispatch(updateContent(axiosInstance, payload, params))
      .then((response) => {
        setEdit(false);
        setToastList([
          {
            id: 0,
            title: "Content Updated",
            description: response.message,
            icon: "success",
          },
        ]);

        // setTimeout(() => {
        //   handleViewContent();
        // }, 1000);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            title: "Error fetching content ",
            description: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleContent = (event) => {
    setContent(event);
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <section className={style.title_filters}>
          <Heading
            title={isFengShuiSetting ? SETTINGS_MANAGEMENT.TERMS_AND_CONDITIONS.HEADING.TITLE :
               SETTINGS_MANAGEMENT.TERMS_OF_SERVICES.HEADING.TITLE
            }
            description={
              isFengShuiSetting ? SETTINGS_MANAGEMENT.TERMS_AND_CONDITIONS.HEADING.DESCRIPTION :
              SETTINGS_MANAGEMENT.TERMS_OF_SERVICES.HEADING.DESCRIPTION
            }
            fontSize={'30px'}
          />
          <div className={style.filters}>
            {edit ? (
              <Button
                onClick={handleEdit}
                text={isFengShuiSetting ? SETTINGS_MANAGEMENT.TERMS_AND_CONDITIONS.BUTTONS.SAVE_TITLE : 
                  SETTINGS_MANAGEMENT.TERMS_OF_SERVICES.BUTTONS.SAVE_TITLE}
                buttonType={`${buttonStyle.button_primary}`}
              />
            ) : (
              <Button
                icon={"/icons/edit.svg"}
                text={isFengShuiSetting ? SETTINGS_MANAGEMENT.TERMS_AND_CONDITIONS.BUTTONS.TITLE :
                   SETTINGS_MANAGEMENT.TERMS_OF_SERVICES.BUTTONS.TITLE}
                onClick={onEdit}
              />
            )}
          </div>
        </section>
        <section className={style.content}>
          {edit ? (
            <h3 className={style.heading}>Edit Content</h3>
          ) : (
            <h3 className={style.heading}>Content</h3>
          )}
          {edit ? (
            <Editor content={content} setContent={handleContent} />
          ) : (
            <Editor content={content} readyOnly={true} />
          )}

          <div>

          </div>
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default TermsOfService;
