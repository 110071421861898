import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import style from './ViewCategory.module.scss';
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { CATEGORY } from "../category/categoryManagementConstant";
import Back from "../../../../shared/components/backButton/backButton";
import Heading from "../../../../shared/components/heading/heading";
import Button from "../../../../shared/components/button/button";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import TitleAndDetail from "../../../../components/FengShui/TitleAndDetail/TitleAndDetail";
import { Status } from "../../../../shared/globalConstants/constants";
import Input from "../../../../shared/components/inputFields/input";
import { deleteTip, editTip, fetchTipsListing } from "../../../../redux/FengShui/manageTips/manageTips.actions";
import Table from "../../../../shared/components/table/table";
import { deleteAdminCategory, viewAdminCategory } from "../../../../redux/FengShui/adminCategory/adminCategory.actions";
import { TIPS } from "../Tips/tipsManagementConstant";
import useExtractSubstring from "../../../../hooks/useExtractSubstring";

const ViewCategory = () => {
    const inputFields = [
        {
            type: "radio",
            name: "status",
            placeholder: 'Status',
            label: 'Status',
            options: Status,
            width: '50%'
        },

    ];

    const location = useLocation();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const [tips, setTips] = useState([])
    const [page, setPage] = useState(1)
    const [totalItem, setTotalItem] = useState(0)
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState({ value: false, id: '' })
    const [showCategoryDelete, setShowCategoryDelete] = useState(false)
    const [formData, setFormData] = useState({
        'status': true
    })
    const { id } = useParams()
    const category = useSelector(store => store.adminCategory.data)
    const state = useSelector(store => store)
    const [ids, setIds] = useState([])

    useEffect(() => {
        const queryParams = {
            // isAdmin: true,
        }
        viewCategoryDetail(axiosInstance, queryParams);
    }, []);
    
    useEffect(() => {
        if(category) {
            const params = {
                languageId: state?.getLanguages?.languageId,
                page: 1,
                // limit: pageSize,
                limit: 10,
                categoryId: category._id,
                keyword: '',
                isAdmin: true,
              }
            getTips(axiosInstance, {}, params);
        }
    }, [category])

    const getTips = (axiosInstance, payload, params) => {
        dispatch(fetchTipsListing(axiosInstance, payload, params))
          .then((response) => {
            const updatedTips = response.data.data.listingData.map((tip, index) => {
              return {
                id: tip._id,
                "sNo": index + 1,
                categoryName: tip?.categoryId?.categoryName,
                createdDate: moment(tip?.created_at).format("MM / DD / YYYY"),
                status: tip?.status,
                featuredTip: tip?.featuredTip
              }
            })
            setTips(updatedTips)
            setTotalItem(response?.data?.data?.meta?.total)
          })
          .catch((err) => {
            setToastList([
              {
                id: 0,
                title: "Fething Tips Failed",
                description: err?.response?.data?.message,
                icon: "error",
              },
            ]);
          });
      };

    const onDeleteTip = (type) => {
        if(type === 'Cancel') {
            setShowDelete({ value: false, id: '' })
        } else if (type === 'Ok') {
          if(showDelete.id) {
            deleteItem([showDelete.id])
          } else {
            deleteItem(ids)
          }
        }
    }

    const deleteItem = (idList) => {
        dispatch(deleteTip(axiosInstance, {ids: idList}, {})).then(res => {
            setShowDelete({ value: false, id: '' })
            const params = {
                languageId: state?.getLanguages?.languageId,
                page: 1,
                // limit: pageSize,
                limit: 10,
                categoryId: category._id,
                isAdmin: true,
              }
            getTips(axiosInstance, {}, params);
        }).catch(error => {
            console.log(error)
            setShowDelete({ value: false, id: '' })
            setToastList([{
                id: 0,
                title: `Error deleting Tip${idList.length >= 2 ? 's': '' }`,
                description: error.response.data.message,
                icon: "error",
            }])
        })
      }

    const onDeleteCategory = (type) => {
        if(type === 'Cancel') {
            setShowCategoryDelete(false)
        } else if (type === 'Ok') {
            dispatch(deleteAdminCategory(axiosInstance, {ids: [category?._id]}, {})).then(res => {
                setShowCategoryDelete(false)
                navigate(-1)
            }).catch(error => {
                setShowCategoryDelete(false)
                setToastList([{
                    id: 0,
                    title: "Error deleting Category",
                    description: error.response.data.message,
                    icon: "error",
                }])
            })
        }
    }

    const handlePage = (page) => {
        setPage(page);
      };

    const editCategoryNavigation = () => navigate(`/tipsManagement/Category/editCategory/${id}`, { state: { id: id } })

    const viewCategoryDetail = (axiosInstance, params) => {
        dispatch(viewAdminCategory(axiosInstance, id, params)).then((response) => {
        }).catch((error) => {
            setToastList([
                {
                    id: 0,
                    title: "Error Fetching Category",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleToggle = (item) => [
        dispatch(editTip(axiosInstance, { featuredTip: !item.featuredTip}, item.id, {})).then((response) => {
            setTips(e => {
                const newTips = e.map(value => {
                    if(value.id === item.id) {
                        return {...value, featuredTip: !value.featuredTip}
                    }
                    return value
                })
                return newTips
            })
        }).catch((error) => {
            setToastList([
                {
                    id: 0,
                    title: "Error Featured Tip",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        })
    ]

    const onTipDelete = item => setShowDelete({ value: true, id: item.id })
    const handleMultiple = (event) => setIds(event)

    const onView = (e) => navigate(`/tipsManagement/viewTips/${e.id}`)
    const onEdit = (e) => navigate(`/tipsManagement/editTips/${e.id}`, { state: { id: e.id } })

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <CenterModal
                    isOpen={showDelete?.value}
                    onClose={() => {
                        setShowDelete({ value: false, id: '' })
                    }}
                    minWidth={"300px"}
                    >
                    <Delete
                        title={"Delete Tip"}
                        description={`Are you sure you want to delete this Tip?`}
                        onButtonClick={onDeleteTip}
                    />
                </CenterModal>
                <CenterModal
                    isOpen={showCategoryDelete}
                    onClose={() => {
                        setShowCategoryDelete(false)
                    }}
                    minWidth={"300px"}
                    >
                    <Delete
                        title={"Delete Category"}
                        description={"Are you sure you want to delete this Category?"}
                        onButtonClick={onDeleteCategory}
                    />
                </CenterModal>
                <div className={style.back}>
                    <Back title={CATEGORY.VIEW_CATEGORY.BACK.TITLE} description={CATEGORY.VIEW_CATEGORY.BACK.DESCRIPTION} />
                </div>
                <div className={style.heading}>
                    {/* <Heading title={userDetails?._id?.slice(0, 5)} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} /> */}
                    <Heading title={useExtractSubstring(category?._id)} description={CATEGORY.VIEW_CATEGORY.HEADING.DESCRIPTION} />
                    <div className={style.action_buttons}>
                        <Button icon={'/icons/edit.svg'} text={"Edit Category"} onClick={editCategoryNavigation} />
                        <Button icon={'/icons/delete.svg'} text={"Delete Category"} onClick={() => setShowCategoryDelete(true)} />
                    </div>
                </div>
                <div className={style.details}>
                    <div className={style.container_images}>
                        <div className={style.main_image_container}>
                            <img src={category?.imgPath ? category?.imgPath : "/icons/user_blue.svg"} />
                        </div>
                        <div className={style.other_images_container}>
                            {category?.subImages && category.subImages.map(img => <img src={img ? img : "/icons/user_blue.svg"}  />)}
                        </div>
                    </div>
                    <div className={style.box}>
                        <div className={style.box_left}>
                            <TitleAndDetail title="Category Name" detail={category?.categoryName} />
                            <TitleAndDetail title="Created On" detail={moment(category?.created_at).format("MM / DD / YYYY")} />
                        </div>
                        <div className={style.box_right}>
                            <TitleAndDetail title="Category Sub Heading" detail={category?.subHeading} />
                            {inputFields.map((inputField, index) => (
                                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                    <Input
                                        type={inputField.type}
                                        name={inputField.name}
                                        label={inputField.label}
                                        value={category?.status}
                                        placeholder={inputField.placeholder}
                                        options={inputField.options}
                                        required={inputField.required}
                                        onChange={() => {}}
                                        handleInputBlur={() => {}}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <section className={style.listing}>
                <div className={style.heading}>
                    <h3 className={style.label}>Tips List</h3>
                    {ids?.length >= 1 && <Button icon={"/icons/delete.svg"} onClick={() => setShowDelete({ value: true, id: '' })} />}
                </div>
                <Table
                    data={tips}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onTipDelete}
                    totalItem={totalItem}
                    handlePage={handlePage}
                    pageNumber={page}
                    noDataText={TIPS.VIEW_TIPS.TIPS_LIST.NOT_FOUND}
                    marginTop={'100px'}
                    handleMultiple={() => {}}
                    handleToggle={handleToggle}
                    handleMultiple={(handleMultiple)}
                />
                </section>
            </div>
        </div>
    )
}

export default ViewCategory;