import AppSwitchButton from "../../../../../../shared/components/appSwitchButton/appSwitchButton";
import Back from "../../../../../../shared/components/backButton/backButton"
import Button from "../../../../../../shared/components/button/button";
import Heading from "../../../../../../shared/components/heading/heading";
import { ADVICE_MANAGEMENT } from "../../../advice.constant";
import style from './addContent.module.scss';
import { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import buttonStyle from "../../../../../../shared/components/button/button.module.scss";
import Editor from "../../../../../../shared/components/editor/editor";
import ImageUpload from "../../../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../../../shared/components/inputFields/input";
import { useParams } from "react-router-dom";
import { addContentIdData } from "../../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { isLoading } from "../../../../../../redux/progressLoader/progressLoader.actions";
import Chip from "../../../../../../shared/components/chips/chips";
import AWS from 'aws-sdk'
import Toaster from "../../../../../../components/Global/Toaster/Toaster.component";
import { addContent } from "../../../../../../redux/AurLaVieAdmin/manageContent/manageContent.actions";
import { generateUUID } from "../../../../../../hooks/uniqueIdGenerator";

const ViewUser = () => {
    const { id } = useParams()
    const location = useLocation();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [contentDetails, setContentDetails] = useState(location.state.data);
    const [containers, setContainers] = useState([]);
    const [tags, setTags] = useState([]);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [contentId, setContentId] = useState("");
    const [headImage, setHeadImage] = useState(null);
    const [tagsValue, setTagsValue] = useState("");
    const [mainImage, setMainImage] = useState(null);
    const [tagHeading, setTagHeading] = useState("");
    const navigate = useNavigate();




    AWS.config.update({
        accessKeyId: "AKIA24FEBSB6D3SDB5XV",
        secretAccessKey: "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",
    });

    const myBucket = new AWS.S3({
        params: { Bucket: "aurlavie" },
        region: "us-east-1",
    });

    const uploadToS3 = async (file, name, containerIndex, subContainerIndex, select) => {
        const id = generateUUID()
        const fileKey = `content-${id}`;
        const params = {
            Body: file,
            Bucket: "aurlavie",
            Key: fileKey,
        };
        try {

            dispatch(isLoading(true))
            const upload = await myBucket.upload(params).promise();
            if (upload) {
                dispatch(isLoading(false));
                if (select === "container") {

                    handleInputChange(upload.Location, subContainerIndex, containerIndex, "img")
                } else {

                    handleMainContent(upload.Location, select)
                }

            }
        } catch (error) {
            console.log(error);
            setToastList([
                {
                    id: 0,
                    title: "Error",
                    description: "Error while uploading the resume.",
                    icon: "error",
                },
            ]);
            dispatch(isLoading(false));
        }
    };




    const addContainer = () => {
        setContainers([...containers, { heading: "" }]);
    };


    const removeContainer = (index) => {
        const updatedContainers = [...containers];
        updatedContainers.splice(index, 1);
        setContainers(updatedContainers);
    };

    const addsubContainer = (index) => {

        let updatedContainers = JSON.parse(JSON.stringify([...containers]))

        if (!updatedContainers[index]?.images) {
            updatedContainers[index][`images`] = []
        }
        updatedContainers[index]?.images?.push({ imgName: "", imgPath: "" })

        setContainers([...updatedContainers]);
    };

    const removeSubContainer = (index, containerDetails) => {
        let updatedContainers = JSON.parse(JSON.stringify([...containers]));
        updatedContainers[containerDetails].images.splice(index, 1);
        setContainers([...updatedContainers]);
    };

    const handleInputChange = (value, subContainerIndex, containerIndex, select) => {

        let updatedContainers = JSON.parse(JSON.stringify([...containers]));

        if (select === "subcontainer") {
            updatedContainers[containerIndex].images[subContainerIndex].imgName = value;
        }
        else if (select === "img") {
            updatedContainers[containerIndex].images[subContainerIndex].imgPath = value;
        }
        else if (select === "heading") {
            updatedContainers[containerIndex].heading = value;
        }



        setContainers([...updatedContainers]);
    }

    const handleMainContent = (value, select) => {
        let updatedContainersHead = JSON.parse(JSON.stringify(contentDetails));


        if (select === "mainImage") {
            setMainImage(value)
        }
        if (select === "headImage") {
            setHeadImage([value])
        }

        setContentDetails(updatedContainersHead)
    }


    const handleFile = (url, containerIndex, subContainerIndex) => {

        if (url === " ") {
            handleInputChange("", subContainerIndex, containerIndex, "img")
        } else if (url && url.name) {
            uploadToS3(url, url.name, containerIndex, subContainerIndex, "container")
        }
    };


    const handleMainImage = (url, imageType) => {

        if (url === " ") {
            handleMainContent("", imageType)
        }
        else if (url && url.name) {
            uploadToS3(url, url.name, null, null, imageType)
        }
    };

    const handleSave = () => {
        let updatedContainersHead = JSON.parse(JSON.stringify(contentDetails));


        if (containers.length) {
            updatedContainersHead.subHeadings = containers

        }


        if (tags.length) {

            updatedContainersHead[`tagHeadings`] = [
                {
                    tags: tags.map(function (item) {
                        return item.label;
                    })
                }
            ]

        }

        if (tagHeading.trim().length) {

            if (updatedContainersHead?.tagHeadings) {
                updatedContainersHead.tagHeadings[0].heading = tagHeading;
            } else {
                updatedContainersHead.tagHeadings = [
                    { heading: tagHeading }
                ]
            }
        }

        if (title.trim().length) {
            updatedContainersHead.title = title;
        }

        if (headImage) {
            updatedContainersHead.headImgPath = headImage;
        }


        updatedContainersHead.content = description;
        updatedContainersHead.contentId = contentId;
        updatedContainersHead.modified_by = localStorage.getItem('userId');
        updatedContainersHead.created_by = localStorage.getItem('userId');
        updatedContainersHead.status = "active";
        updatedContainersHead.languageId = localStorage.getItem('languageId');
        updatedContainersHead.mainImgPath = mainImage;
        updatedContainersHead.adviceId = updatedContainersHead._id;
        updatedContainersHead.parentAdviceId = updatedContainersHead.parentId._id;





        dispatch(addContentIdData(axiosInstance, updatedContainersHead)).then(res => {
            setToastList([
                {
                    id: 0,
                    title: "Add Success",
                    description: "Content added Successfully.",
                    icon: "success",
                },
            ]);

            setTimeout(() => {
                navigate(-1);
            }, 1000)

        }).catch(err => {
            setToastList([
                {
                    id: 0,
                    title: "Add Content Error",
                    description: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        })



    }

    const remove = (chipToBeRemoved) => {
        const newChips = tags.filter((tag) => tag.label !== chipToBeRemoved.label);
        setTags(newChips);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            const newTag = event.target.value;
            setTags([...tags, { label: newTag }]);
            event.target.value = "";
            setTagsValue("")

        }
    };

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.back}>
                    <Back title={ADVICE_MANAGEMENT.ADD_CONTENT_DATA.BACK.DESCRIPTION} description={ADVICE_MANAGEMENT.ADD_CONTENT_DATA.BACK.TITLE} />
                </div>
                <div className={style.heading}>
                    <Heading title={ADVICE_MANAGEMENT.ADD_CONTENT_DATA.HEADING.TITLE} description={ADVICE_MANAGEMENT.ADD_CONTENT_DATA.HEADING.DESCRIPTION} />
                    <div className={style.action_buttons}>
                        <Button
                            text={ADVICE_MANAGEMENT.ADD_CONTENT_DATA.BUTTONS.SAVE.TITLE}
                            buttonType={`${buttonStyle.button_primary}`}
                            marginTop={0}
                            onClick={handleSave}
                        />
                    </div>
                </div>
                <section className={style.content}>
                    <div className={style.upload}>
                        <h3 className={style.heading}>Main Image</h3>
                        <ImageUpload
                            id="mainImage"
                            handleFile={(url) => handleMainImage(url, "mainImage")} />
                    </div>
                    <div className={style.inner_content}>
                        <Input
                            type="text"
                            name="contentId"
                            placeholder="Content Id"
                            label="Content Id"
                            value={contentId}
                            mainWidth={'100%'}
                            onChange={(value) => setContentId(value)}
                        />
                        {contentDetails && contentDetails?.parentId?.adviceLabel !== "food" ?
                            <div className={style.conditional_input}>
                                <Input
                                    type="text"
                                    name="subHeading"
                                    placeholder="Subheading"
                                    label="Title"
                                    value={title}
                                    mainWidth={'100%'}
                                    onChange={(value) => setTitle(value)}
                                />
                            </div> : null}

                        {contentDetails && contentDetails?.parentId
                            ?.adviceLabel !== "food" ?
                            <div className={style.editor_container}>
                                <h3 className={style.heading}>Head Image</h3>
                                <ImageUpload height={`80px`} width={`80px`} id="headImage" handleFile={(url) => handleMainImage(url, "headImage")} />
                            </div>
                            : null}



                        <div className={style.editor_container}>
                            <h3 className={style.heading}>Content</h3>
                            <Editor setContent={(data) => setDescription(data)} content={description} />
                        </div>

                        {contentDetails && contentDetails?.parentId
                            ?.adviceLabel !== "perfumes" ?
                            containers &&
                                containers?.length ?
                                containers?.map((content, outerIndex) => {
                                    return (

                                        <div key={outerIndex} className={style.row}>
                                            <div className={style.subHeading}>

                                                <Input
                                                    type="text"
                                                    name="subHeading"
                                                    placeholder="Subheading"
                                                    label="Sub Heading"
                                                    value={content.heading}
                                                    mainWidth={'100%'}
                                                    onChange={(value) => handleInputChange(value, null, outerIndex, "heading")}
                                                />
                                                <Button icon={"/icons/delete.svg"} onClick={() => removeContainer(outerIndex)} />
                                            </div>


                                            <div className={style.action_buttons}>
                                                <Button
                                                    text={"+ Add Sub Container"}
                                                    buttonType={`${buttonStyle.button_secondary}`}
                                                    marginTop={0}
                                                    onClick={() => addsubContainer(outerIndex)}
                                                />
                                            </div>
                                            <div className={style.subcontent_divider}>
                                                <div className={style.label_container}>

                                                    {content?.images?.length ? content?.images.map((data, innerIndex) => (
                                                        <div key={innerIndex} className={style.content}>

                                                            <ImageUpload containerIndex={outerIndex} subContainerIndex={innerIndex} id={`${outerIndex}${innerIndex}`} handleFile={(data) => handleFile(data, outerIndex, innerIndex)} previewImage={data.imgPath} height={'80px'} width={'80px'} />

                                                            <Input
                                                                type="text"
                                                                name="imageName"
                                                                placeholder="Image Name"
                                                                label="Image Name"
                                                                value={data.imgName}

                                                                onChange={(value) => handleInputChange(value, innerIndex, outerIndex, "subcontainer")}

                                                                mainWidth={'100%'}

                                                            />
                                                            <Button icon={"/icons/delete.svg"} onClick={() => removeSubContainer(innerIndex, outerIndex)} />
                                                        </div>
                                                    )) : null}
                                                </div>
                                            </div>
                                            <div className={style.action_buttons}>
                                                <Button
                                                    text={"+ Add Subheading"}
                                                    buttonType={`${buttonStyle.button_secondary}`}
                                                    marginTop={0}
                                                    onClick={() => addContainer()}
                                                />
                                            </div>
                                        </div>

                                    )
                                }) : <div className={style.action_buttons}>
                                    <Button
                                        text={"+ Add Subheading"}
                                        buttonType={`${buttonStyle.button_secondary}`}
                                        marginTop={0}
                                        onClick={() => addContainer()}
                                    />
                                </div>
                            :
                            <div className={style.form_container}>
                                <div className={style.conditional_input}>
                                    <Input
                                        type="text"
                                        name="subHeading"
                                        placeholder="Subheading"
                                        label="Tag Heading"
                                        value={tagHeading}
                                        mainWidth={'100%'}
                                        onChange={(value) => setTagHeading(value)}
                                    />
                                </div>
                                <div className={style.input_field} >
                                    <div className={style.tags}>
                                        <Input
                                            type="text"
                                            name="tags"
                                            placeholder="Add tag and press enter"
                                            label="Tags"
                                            value={tagsValue}
                                            onKeyDown={handleKeyDown}
                                            onChange={(value) => setTagsValue(value)}
                                        />
                                        {/* <img src="/icons/add.svg" alt="add" className={style.add} /> */}
                                    </div>
                                </div>
                                <div className={style.chips}>
                                    <Chip data={tags.length ? tags : null} showRemove={true} onRemove={remove} />
                                </div>
                            </div>
                        }
                    </div>
                </section>
            </div >
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </div >
    )
}

export default ViewUser;