import { useEffect, useRef, useState } from "react";
import Heading from "../../../shared/components/heading/heading";
import Table from "../../../shared/components/table/table";
import { NOTIFICATION_MANAGEMENT } from "./notification.constant";
import "../userManagement/userManagement.scss";
import Input from "../../../shared/components/inputFields/input";
import { AppType } from "../../../shared/globalConstants/constants";
import Button from "../../../shared/components/button/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  deleteNotification,
  fetchNotifications,
} from "../../../redux/AurLaVieAdmin/manageNotifications/manageNotification.actions";
import { languageState } from "../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

import { selectUsersListingData } from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.selectors";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import moment from "moment";
import Toaster from "../../../components/Global/Toaster/Toaster.component";
import CenterModal from "../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../shared/components/delete/delete";

const NotificationManagment = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedApplicationType, setSelectedApplicationType] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allUsersdaa = useSelector(selectUsersListingData);
  const languageData = useSelector(languageState);
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const currentDeleteRef = useRef(null);

  const [ids, setIds] = useState(null);

  const location = useLocation()
  const isFengShuiSetting = location.pathname === NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI
  const appId = isFengShuiSetting ? "641bdbd22b1809d1b4c83976" : localStorage.getItem("appId")
  const languageId = localStorage.getItem("languageId") || languageData?.languageId

  // const [appId, setAppId] = useState(localStorage.getItem("appId"));
  // const [languageId, setLanguageId] = useState(
  //   localStorage.getItem("languageId")
  // );

  const handleNotifications = () => {
    currentDeleteRef.current = null;
    setShowDeleteModal(false);
    const params = {
      page,
      limit: 10,
    };

    const payload = {
      appId,
      languageId,
    };
    getNotifications(axiosInstance, payload, params);
  };

  useEffect(() => {
    if (languageId) {
      const params = {
        page: 1,
        limit: 10,
      };

      const payload = {
        appId,
        languageId,
      };
      getNotifications(axiosInstance, payload, params);
    }
  }, [languageId, isFengShuiSetting]);

  useEffect(() => {
    handleNotifications();
  }, [page]);

  useEffect(() => {
    setPage(1);
    let timerId;
    const fetchResults = async () => {
      try {
        const params = {
          page: 1,
          limit: pageSize,
        };

        const payload = {
          appId,
          languageId,
          keyword: searchTerm,
        };

        getNotifications(axiosInstance, payload, params);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const getNotifications = (axiosInstance, payload, params) => {
    dispatch(fetchNotifications(axiosInstance, payload, params))
      .then((response) => {
        const updatedNotifications = response?.data?.data?.listingData.map(
          (data, index) => {
            const newIndex = ((page - 1) * pageSize) + index + 1;
            return {
              id: data?._id,
              "S. No": newIndex > 100
                ? newIndex
                : newIndex < 100 && newIndex > 9
                  ? `0${newIndex}`
                  : `00${newIndex}`,
              "Notification Title": data?.title,
              "Notification Content": data?.description,
              "Created On": moment(data?.created_at).format("MM/DD/YYYY"),
              "Triggered Date": data.scheduleDate
                ? moment(data?.scheduleDate).format("MM/DD/YYYY")
                : null,
            };
          }
        );
        setNotifications(updatedNotifications);
        setTotalItem(response?.data?.data?.meta?.total);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            title: "Fething Notification Failed",
            description: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  const onEdit = (data) => {
    isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.EDIT_NOTIFICATION.replace(/:id/g, data.id)) : navigate(`/notifications/editNotification/${data.id}`)
  };

  const onDelete = (data) => {
    currentDeleteRef.current = [data.id];
    setShowDeleteModal(true);
  };

  const onView = (event) => {
    isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.VIEW_NOTIFICATION.replace(/:id/g, event.id)) : navigate(`/notifications/viewNotification/${event.id}`)
  };

  const addUser = () => {
    isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.ADD_NOTIFICATION) : navigate("/notifications/addNotification")
  };

  const handleMultiple = (event) => {
    setIds(event);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const deletemany = () => {
    if (ids.length) {
      setShowDeleteModal(true);
    }
  };

  const handleDeleteNotification = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
      currentDeleteRef.current = null;
    } else {
      const data = currentDeleteRef.current?.length
        ? { ids: currentDeleteRef.current }
        : { ids };

      dispatch(deleteNotification(axiosInstance, data))
        .then((res) => {
          setIds(null);
          currentDeleteRef.current = null;
          setToastList([
            {
              id: 0,
              title: "Notifications Deleted Successfully",
              description: res.message,
              icon: "success",
            },
          ]);
          setTimeout(() => {
            handleNotifications();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              title: "Delete Notification Error",
              description: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  return (
    <section className="container">
      <div className="wrapper">
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={NOTIFICATION_MANAGEMENT.DELETE_NOTIFICATION.TITLE}
            description={
              NOTIFICATION_MANAGEMENT.DELETE_NOTIFICATION.DESCRIPTION
            }
            onButtonClick={handleDeleteNotification}
          />
        </CenterModal>
        <section className="title_filters">
          <Heading
            title={NOTIFICATION_MANAGEMENT.HEADING.TITLE}
            description={NOTIFICATION_MANAGEMENT.HEADING.DESCRIPTION}
            icon="/icons/notifications.svg"
          />
          <div className="filters">
            <Button
              icon={"/icons/add.svg"}
              text={"Add Notification"}
              onClick={addUser}
            />
            <Input
              type={"search"}
              placeholder={NOTIFICATION_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              options={AppType}
              onChange={onSearch}
            />
            <Button icon={"/icons/delete.svg"} onClick={deletemany} />
          </div>
        </section>
        <section className="listing">
          <Table
            noDataText={"No Notifications found"}
            handleMultiple={handleMultiple}
            data={notifications}
            onView={(event) => onView(event)}
            onEdit={(event) => onEdit(event)}
            onDelete={onDelete}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default NotificationManagment;
