import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProgressLoaderComponent from "../../components/Global/ProgressLoader/ProgressLoader.component";
import { getAppById } from '../../redux/AurLaVieAdmin/apps/getApps.action';
import { axiosInstance } from "../../constants/axiosInstance";


const RedirectAppPage = () => {
  const [options, setOptions] = useState([]);
  const [appId, setAppId] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    handleRedirection();
  }, []);

  const getAppInfo = (axiosInstance, appId, urlToken) => {
    dispatch(getAppById(axiosInstance, appId)).then((res) => {
      console.log(res)

      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; // android check
      let isIphone = ua.indexOf("iphone") > -1; // ios check

      let iosPackageUrl = res.iosPackageUrl;
      let iosStoreUrl = res.iosStoreUrl;
      let androidPackageUrl = res.androidPackageUrl;
      let playStoreUrl = res.playStoreUrl;

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let pageType = params.get('type');
      let pageName;
      if(pageType == 'forgotpassword'){
        pageName = `forgotpassword/${urlToken}`;
      } else if(pageType == 'login'){
        pageName = 'login';
      }
      
      if (isIphone == true) {
        let app = {
          launchApp: function () {
            // window.location.href = `com.aurlavie://forgotpassword/${urlToken}`; //which page to open(now from mobile, check its authorization)
            window.location.href = `${iosPackageUrl}${pageName}`; //which page to open(now from mobile, check its authorization)
            setTimeout(this.openWebApp, 500);
          },
          openWebApp: function () {
            window.location.href = iosStoreUrl;
              // "https://apps.apple.com/us/app/aur-la-vie/id1456340470";
          },
        };
        app.launchApp();
      } else if (isAndroid == true) {
        let app = {
          launchApp: function () {
            // window.location.href = `https://com.aurlavie/${urlToken}`; //which page to open(now from mobile, check its authorization)
            window.location.href = `${androidPackageUrl}/${urlToken}`; //which page to open(now from mobile, check its authorization)
            setTimeout(this.openWebApp, 500);
          },
          openWebApp: function () {
            window.location.href = playStoreUrl;
              // "https://play.google.com/store/apps/details?id=com.fashioncolor.fashioncolorapp";
          },
        };
        app.launchApp();
      } else {
        let app = {
          launchApp: function () {
            setTimeout(
              (window.location.href = playStoreUrl),
                // "https://play.google.com/store/apps/details?id=com.fashioncolor.fashioncolorapp"),
              500
            );
          },
        };
        app.launchApp();

        //navigate to website url
      }
    }).catch((error) => {
      console.log('err', error.response.data.message);      
    });
  };

  const handleRedirection = () => {
    const routeSplit = location?.pathname.split("/");
    // console.log(routeSplit);
    const appId = routeSplit[2];
    // console.log("appId",appId)
    setAppId(appId);
    const urlToken = routeSplit[routeSplit.length - 1];
    getAppInfo(axiosInstance, appId, urlToken);
    
  };

  return <ProgressLoaderComponent />;
};
export default RedirectAppPage;