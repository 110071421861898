import AppSwitchButton from "../../../../shared/components/appSwitchButton/appSwitchButton";
import Back from "../../../../shared/components/backButton/backButton"
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import { USER_MANAGEMENT } from "../userManagementConstants";
import style from './viewUser.module.scss';
import { useEffect, useRef, useState } from "react";
import { deleteAppUser, viewUser } from "../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import useExtractSubstring from "../../../../hooks/useExtractSubstring";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import Table from "../../../../shared/components/table/table";
import buttonStyle from '../../../../shared/components/button/button.module.scss'

const TIPS = [
    {
        "id": "64899633490ae3f4a37aafda",
        "sNo": 1,
        "tipName": "Image 4",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    },
    {
        "id": "64899541490ae3f4a37aaf95",
        "sNo": 2,
        "tipName": "Image 4",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    },
    {
        "id": "648994d2490ae3f4a37aaf70",
        "sNo": 3,
        "tipName": "Image 3",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    },
    {
        "id": "6489940d490ae3f4a37aaf44",
        "sNo": 4,
        "tipName": "Image 2",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    }
]
const TIPS2 = [
    {
        "id": "64899633490ae3f4a37aafda",
        "sNo": 1,
        "image": "https://aurlavie.s3.amazonaws.com/tip-main-614b6242-eb58-45a9-ab7a-2559cd112f63",
        "tipName": "Image 4",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    },
    {
        "id": "64899541490ae3f4a37aaf95",
        "sNo": 2,
        "image": "https://aurlavie.s3.amazonaws.com/tip-main-601ab30c-2fad-48f1-bede-f8c56b11caec",
        "tipName": "Image 4",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    },
    {
        "id": "648994d2490ae3f4a37aaf70",
        "sNo": 3,
        "image": "https://aurlavie.s3.amazonaws.com/tip-main-86e61c88-32b8-4499-a067-d8d68ba70d00",
        "tipName": "Image 3",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    },
    {
        "id": "6489940d490ae3f4a37aaf44",
        "sNo": 4,
        "image": "https://aurlavie.s3.amazonaws.com/tip-main-912cda9b-3f1e-482e-a53d-5af9017f7e4a",
        "tipName": "Image 2",
        "createdDate": "06 / 14 / 2023",
        "status": "active"
    }
]

const ViewUser = () => {

    const location = useLocation();
    const [toastList, setToastList] = useState([]);
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const fullUserData = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = {
            isAdmin: true
        }
        viewUserDetails(axiosInstance, location.state?.email, queryParams);
    }, []);

    const deleteUser = () => {
        setShowDeleteModal(true);
    }

    const editUser = () => {
        navigate(`/userManagement/editUser/${userDetails._id}`, { state: { email: userDetails.email } });
    }

    const viewUserDetails = (axiosInstance, userId, params) => {
        dispatch(viewUser(axiosInstance, userId, params)).then((response) => {
            if (response.length === 2) {
                fullUserData.current = {
                    length: 2,
                    data: response
                };
            } else {
                fullUserData.current = {
                    length: 1,
                    data: response[0]
                };
            }

            setUserDetails(response[0]);
        }).catch((error) => {
            console.log('err', error.response.data.message);
            setToastList([
                {
                    id: 0,
                    title: "Error adding user",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleDeleteUser = (clicked) => {
        if (clicked === "Cancel") {
            setShowDeleteModal(false);
        } else {
            const params = {
                isAdmin: true
            }
            const data = {
                "userId": userDetails._id,
                "appId": localStorage.getItem("appId")
            }
            dispatch(deleteAppUser(axiosInstance, data, params))
                .then((res) => {
                    setShowDeleteModal(false);
                    setToastList([
                        {
                            id: 0,
                            title: "User Deleted",
                            description: "User Deleted Successfully",
                            icon: "success",
                        },
                    ]);
                    setTimeout(() => {
                        navigate('/userManagement');
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                    setToastList([
                        {
                            id: 0,
                            title: "Delete User Error",
                            description: err?.response?.data?.message,
                            icon: "error",
                        },
                    ]);
                });
        }
    };

    const handleSwitch = (data) => {
        setUserDetails(fullUserData.current.data[0].appId.appName === data ? fullUserData.current.data[0] : fullUserData.current.data[1])
    }

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <CenterModal isOpen={showDeleteModal} onClose={() => { setShowDeleteModal(false); }} minWidth={"300px"}>
                    <Delete title={USER_MANAGEMENT.DELETE.TITLE} description={USER_MANAGEMENT.DELETE.DESCRIPTION} onButtonClick={handleDeleteUser} />
                </CenterModal>
                <div className={style.back}>
                    <Back title={USER_MANAGEMENT.VIEW_USER.BACK.TITLE} description={USER_MANAGEMENT.VIEW_USER.BACK.DESCRIPTION} />
                </div>
                <div className={style.heading}>
                    <Heading title={useExtractSubstring(userDetails?._id)} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} />
                    <div className={style.action_buttons}>
                        <AppSwitchButton length={fullUserData?.current?.length} handleSwitch={handleSwitch} selectedApp={userDetails?.appId?.appName} />
                        <Button icon={'/icons/edit.svg'} onClick={editUser} />
                        <Button icon={'/icons/delete.svg'} onClick={deleteUser} />
                    </div>
                </div>
                <div className={style.details}>
                    <div className={style.profile_image}>
                        <img src={userDetails?.profilePic ? userDetails?.profilePic : "/icons/user_blue.svg"} alt="profile" />
                    </div>
                    <div className={style.profile_details}>
                        <Heading title={USER_MANAGEMENT.VIEW_USER.HEADING.TITLE} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} fontSize={'1.4rem'} />
                        <hr className={style.divider} />
                        <div className={style.column}>
                            <div className={style.row}>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>User Id</label>
                                    <p className={style.value}>{userDetails?._id?.slice(0, 5)}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Name</label>
                                    <p className={style.value}>{userDetails?.name}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Email Id</label>
                                    <p className={style.value}>{userDetails?.email}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Using App</label>
                                    <p className={style.value}>{userDetails?.appId?.appName}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Created On</label>
                                    <p className={style.value}>{moment(userDetails?.created_at).format("MM/DD/YYYY")}</p>
                                </div>
                                <div className={style.sub_detail}>
                                    <label className={style.label}>Active User</label>
                                    <p className={style.value} style={{ textTransform: "capitalize" }}>{userDetails?.status}</p>
                                </div>
                                {userDetails?.appId?.appName === "Aur-La-Vie" && <div className={style.sub_detail}>
                                    <label className={style.label}>VIP User</label>
                                    <p className={style.value}>{userDetails?.isVip ? 'Yes' : 'No'}</p>
                                </div>}
                            </div>
                        </div>
                        {userDetails?.userProfileData?.length > 0 && <div className={style.astrology_details}>
                            <Heading title={'Astrology Profiles'} description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION} fontSize={'1.4rem'} />
                            {
                                userDetails?.userProfileData && userDetails?.userProfileData.length > 0 && userDetails?.userProfileData.map((profile, index) => {
                                    return <div key={index}>
                                        <hr className={style.divider} />
                                        <h4 className={style.sub_heading}>Astrology Profile {index + 1}</h4>
                                        <div className={style.astrologers}>
                                            <div className={style.profile_image}>
                                                <img src={profile?.profilePic ? profile?.profilePic : "/icons/user_blue.svg"} alt="profile" />
                                            </div>
                                            <div className={style.column}>
                                                <div className={style.row}>
                                                    <div className={style.sub_detail}>
                                                        <label className={style.label}>Full Name</label>
                                                        <p className={style.value}>{profile?.name}</p>
                                                    </div>
                                                    <div className={style.sub_detail}>
                                                        <label className={style.label}>Date Of Birth</label>
                                                        <p className={style.value}>{moment.utc(profile?.dob).format('MM/DD/YYYY')}</p>
                                                    </div>
                                                </div>
                                                <div className={style.row}>
                                                    <div className={style.sub_detail}>
                                                        <label className={style.label}>Birth Time</label>
                                                        <p className={style.value}>{moment.utc(profile?.dob).format('h:mm A')}</p>
                                                    </div>
                                                    <div className={style.sub_detail}>
                                                        <label className={style.label}>Are You Twin</label>
                                                        <p className={style.value}>{profile?.twins ? 'Yes' : 'No'}</p>
                                                    </div>
                                                </div>
                                                <div className={style.row}>
                                                    <div className={style.sub_detail}>
                                                        <label className={style.label}>Gender</label>
                                                        <p className={style.value}>{profile?.gender}</p>
                                                    </div>
                                                    <div className={style.sub_detail}>
                                                        <label className={style.label}>Sexual Preference</label>
                                                        <p className={style.value}>{profile?.sexualPreference}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>}
                    </div>
                </div>




                {/* Beta User */}

                <div className={style.header_box}>
                    <div className={style.headings}>
                        <h3 className={style.headings_main}>Property Profile Details</h3>
                        <p className={style.headings_sub}>Property Profile Details lora ipsum</p>
                    </div>
                    <Button
                    icon={"/icons/add.svg"}
                    text={"Add Property"}
                    onClick={() => {}}
                    />
                </div>

                <section className={style.listing}>
                <div className={style.heading}>
                    <h3 className={style.label}>Property</h3>
                    {/* {ids?.length >= 1 && <Button icon={"/icons/delete.svg"} onClick={() => setShowDelete({ value: true, id: '' })} />} */}
                    <Button icon={"/icons/delete.svg"} onClick={() => {}} />
                </div>
                <Table
                    data={TIPS}
                    onView={() => {}}
                    onEdit={() => {}}
                    onDelete={() => {}}
                    totalItem={() => {}}
                    handlePage={() => {}}
                    pageNumber={1}
                    noDataText={'No Data'}
                    marginTop={'100px'}
                    handleMultiple={() => {}}
                    handleToggle={() => {}}
                    // handleMultiple={(handleMultiple)}
                />
                {/* <Table
                    data={tips}
                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onTipDelete}
                    totalItem={totalItem}
                    handlePage={handlePage}
                    pageNumber={page}
                    noDataText={TIPS.VIEW_TIPS.TIPS_LIST.NOT_FOUND}
                    marginTop={'100px'}
                    handleMultiple={() => {}}
                    handleToggle={handleToggle}
                    handleMultiple={(handleMultiple)}
                /> */}
                </section>


                <div className={style.header_box}>
                    <div className={style.headings}>
                        <h3 className={style.headings_main}>Resident Details</h3>
                        <p className={style.headings_sub}>Resident Details lora ipsum</p>
                    </div>
                    <Button
                    icon={"/icons/add.svg"}
                    text={"Add Resident"}
                    onClick={() => {}}
                    />
                </div>

                <section className={style.listing}>
                <div className={style.heading}>
                    <h3 className={style.label}>Resident</h3>
                    <Button icon={"/icons/delete.svg"} onClick={() => {}} />
                </div>
                <Table
                    data={TIPS2}
                    onView={() => {}}
                    onEdit={() => {}}
                    onDelete={() => {}}
                    totalItem={() => {}}
                    handlePage={() => {}}
                    pageNumber={1}
                    noDataText={'No Data'}
                    marginTop={'100px'}
                    handleMultiple={() => {}}
                    handleToggle={() => {}}
                    // handleMultiple={(handleMultiple)}
                />
                </section>

                <div className={style.link_button_container}>

                <Button disabled={false} type="button" onClick={() => {}} text="Link Resident With Property" buttonType={buttonStyle.button_primary} />
                </div>



            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </div>
    )
}

export default ViewUser;