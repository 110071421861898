import { useEffect, useState } from 'react';
import Button from '../../../../shared/components/button/button';
import Editor from '../../../../shared/components/editor/editor';
import Heading from '../../../../shared/components/heading/heading';
import { SETTINGS_MANAGEMENT } from '../settings.constant';
import style from './about.module.scss';
import Toaster from '../../../../components/Global/Toaster/Toaster.component';
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { useDispatch, useSelector } from 'react-redux';
import { languageState } from '../../../../redux/AurLaVieAdmin/languages/getLanguages.selector';
import { updateContent, viewContent } from '../../../../redux/AurLaVieAdmin/manageContent/manageContent.actions';
import AWS from "aws-sdk";
import { isLoading } from '../../../../redux/progressLoader/progressLoader.actions';
import { useLocation } from 'react-router-dom';
import { generateUUID } from '../../../../hooks/uniqueIdGenerator';

const About = () => {
    const location = useLocation()
    const isFengShuiSetting = location.pathname === SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI

    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [edit, setEdit] = useState(false);
    const [content, setContent] = useState("");
    const [toastList, setToastList] = useState([]);
    const [contentId, setContentId] = useState(null);
    const languageData = useSelector(languageState);
    // const [appId, setAppId] = useState('');
    const [userImage, setUserImage] = useState("");
    const [aboutImage, setAboutImage] = useState("");

    // useEffect(() => {
    //     const selectedAppId = localStorage.getItem('appId');
    //     setAppId(selectedAppId);
    // }, [appId])

    // useEffect(() => {
    //     handleViewContent();
    // }, [axiosInstance]);

    // useEffect(() => {
    //     if (languageData.languageId) {
    //         handleViewContent();
    //     }
    // }, [languageData]);

    useEffect(() => {
        if (languageData?.languageId) {
            handleViewContent();
        }
    }, [axiosInstance, languageData, isFengShuiSetting]);

    AWS.config.update({
        accessKeyId: "AKIA24FEBSB6D3SDB5XV",
        secretAccessKey: "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",
    });

    const myBucket = new AWS.S3({
        params: { Bucket: "aurlavie" },
        region: "us-east-1",
    });

    const uploadToS3 = async (file, name) => {
        const id = generateUUID();
        const fileKey = `about-${id}`;
        const params = {
            Body: file,
            Bucket: "aurlavie",
            Key: fileKey,
        };
        try {
            dispatch(isLoading(true))
            const upload = await myBucket.upload(params).promise();
            if (upload) {
                dispatch(isLoading(false));
                editAbout(upload.Location);
            }
        } catch (error) {
            console.log(error);
            setToastList([
                {
                    id: 0,
                    title: "Error",
                    description: "Error while uploading the resume.",
                    icon: "error",
                },
            ]);
            dispatch(isLoading(false));
        }
    };

    const handleFile = (url) => {
        if (url) {
            setUserImage(url);
        }
    };

    const handleViewContent = () => {
        setEdit(false);
        const appId = isFengShuiSetting ? "641bdbd22b1809d1b4c83976" : localStorage.getItem("appId");
        const languageId = languageData?.languageId;
        const pageName = isFengShuiSetting ? "aursome" : "about";
        const params = {
            appId,
            languageId,
            pageName,
        };
        dispatch(viewContent(axiosInstance, params))
            .then((response) => {
                setContentId(response?._id);
                setContent(response?.description);
                setAboutImage(response?.images?.[0]);
            })
            .catch((error) => {
                console.log("err", error.response.data.message);
                setToastList([
                    {
                        id: 0,
                        title: "Error fetching content ",
                        description: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
    };

    const onEdit = () => {
        setEdit(true);
    };

    const handleEdit = () => {
        if (userImage.name) {
            uploadToS3(userImage, userImage.name);
        } else {
            editAbout();
        }
    };

    const editAbout = (imageUrl) => {
        let payload = {
            description: '',
            images: []
        };

        if (imageUrl) {
            payload = {
                description: content,
                images: [imageUrl]
            };
        } else {
            payload = {
                description: content,
                images: []
            };
        }
        const params = {
            id: contentId,
        };
        dispatch(updateContent(axiosInstance, payload, params))
            .then((response) => {
                setToastList([
                    {
                        id: 0,
                        title: "Content Updated",
                        description: response.message,
                        icon: "success",
                    },
                ]);
                setEdit(false)
                // setTimeout(() => {
                //     handleViewContent();
                // }, 1000);
            })
            .catch((error) => {
                console.log("err", error.response.data.message);
                setToastList([
                    {
                        id: 0,
                        title: "Error fetching content ",
                        description: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
    };

    const handleContent = (event) => {
        setContent(event);
    };

    return (
        <section className={style.container}>
            <div className={style.wrapper}>
                <section className={style.title_filters}>
                    <Heading
                        title={isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.HEADING.TITLE : SETTINGS_MANAGEMENT.ABOUT.HEADING.TITLE}
                        description={
                            isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.HEADING.DESCRIPTION :
                                SETTINGS_MANAGEMENT.ABOUT.HEADING.DESCRIPTION
                        }
                        fontSize={'30px'}
                    />
                    <div className={style.filters}>
                        {edit ? (
                            <Button
                                text={
                                    isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.BUTTONS.SAVE_TITLE :
                                        SETTINGS_MANAGEMENT.ABOUT.BUTTONS.SAVE_TITLE}
                                buttonType={`${buttonStyle.button_primary}`}
                                onClick={handleEdit}
                            />
                        ) : (
                            <Button
                                icon={"/icons/edit.svg"}
                                text={isFengShuiSetting ? SETTINGS_MANAGEMENT.AURSOME.BUTTONS.TITLE :
                                    SETTINGS_MANAGEMENT.ABOUT.BUTTONS.TITLE}
                                onClick={onEdit}
                            />
                        )}
                    </div>
                </section>
                <section className={style.content}>
                    <div className={style.upload}>
                        <h3 className={style.heading}>Image</h3>
                        <ImageUpload isDisabled={!edit} handleFile={handleFile} previewImage={aboutImage} />
                        {/* <div className={style.inner_uploads}>
                            <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} id={'upload1'} />
                            <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} id={'upload2'} /><ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'8px'} id={'upload3'} />
                        </div> */}
                    </div>
                    <div className={style.inner_content}>
                        {edit ? (
                            <h3 className={style.heading}>Edit Content</h3>
                        ) : (
                            <h3 className={style.heading}>Content</h3>
                        )}

                        {edit ? (
                            <Editor content={content} setContent={handleContent} />
                        ) : (
                            <Editor content={content} readyOnly={true} />
                        )}
                    </div>
                </section>
            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </section>
    )
}

export default About;