import { NOTIFICATION_MANAGEMENT } from "../pages/admin/notification/notification.constant";
import { SETTINGS_MANAGEMENT } from "../pages/admin/settings/settings.constant";

export const routeItems = [
  {
    label: "Login",
    route: "/login",
    component: "LoginPage",
    roleLevel: [],
    protected: false,
  },
  {
    label: "ForgetPassword",
    route: "/forget-password",
    component: "ForgetPassword",
    roleLevel: [],
    protected: false,
  },
  {
    label: "RedirectApp",
    route: "/redirect-app/:appId/:token",
    component: "RedirectApp",
    roleLevel: [],
    protected: false,
  },
  {
    label: "Dashboard",
    route: "/",
    component: "HomePage",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Notification",
    route: "/notifications",
    component: "Notification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Notification",
    route: "/notifications/addnotification",
    component: "AddNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Notification",
    route: "/notifications/editNotification/:id",
    component: "EditNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View Notification",
    route: "/notifications/viewNotification/:id",
    component: "ViewNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "ResetPassword",
    route: "/reset-password/:id",
    component: "ResetPassword",
    roleLevel: [],
    protected: false,
  },
  {
    label: "User Management",
    route: "/userManagement",
    component: "UserManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Feng Shui Notification",
    route: NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI,
    component: "FengShuiNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Feng Shui Add Notification",
    route: NOTIFICATION_MANAGEMENT.URL_PATH.ADD_NOTIFICATION,
    component: "FengShuiAddNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Feng Shui Edit Notification",
    route: NOTIFICATION_MANAGEMENT.URL_PATH.EDIT_NOTIFICATION,
    component: "FengShuiEditNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Feng Shui View Notification",
    route: NOTIFICATION_MANAGEMENT.URL_PATH.VIEW_NOTIFICATION,
    component: "FengShuiViewNotification",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Feng Shui Setting",
    route: SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI,
    component: "FengShuiSetting",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Category",
    route: "/tipsManagement/Category/addCategory",
    component: "AddCategory",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Category",
    route: "/tipsManagement/Category/editCategory/:id",
    component: "EditCategory",
    roleLevel: [],
    protected: true,
  },
  {
    label: "view Category",
    route: "/tipsManagement/Category/viewCategory/:id",
    component: "ViewCategory",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Tips Management",
    route: "/tipsManagement",
    component: "CategoryAndTipsSwitchManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Tips",
    route: "/tipsManagement/addTips",
    component: "AddTips",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Tips",
    route: "/tipsManagement/editTips/:id",
    component: "EditTips",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View Tips",
    route: "/tipsManagement/viewTips/:id",
    component: "ViewTips",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add User",
    route: "/userManagement/addUser",
    component: "AddUser",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View User",
    route: "/userManagement/viewUser",
    component: "ViewUser",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit User",
    route: "/userManagement/editUser/:id",
    component: "EditUser",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Books Management",
    route: "/booksManagement",
    component: "BooksManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Books",
    route: "/booksManagement/addBook",
    component: "AddBooks",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Books",
    route: "/booksManagement/editBook/:id",
    component: "EditBooks",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View Book Section",
    route: "/booksManagement/viewBookSection/:id",
    component: "ViewBookSection",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Book Section",
    route: "/booksManagement/editBookSection/:id",
    component: "EditBookSection",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Book Section",
    route: "/booksManagement/addBookSection",
    component: "AddBookSection",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Settings",
    route: "/settings",
    component: "Settings",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Privacy Policy",
    route: "/settings/privacyPolicy",
    component: "Privacy Policy",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Terms Of Service",
    route: "/settings/termsOfService",
    component: "Terms Of Service",
    roleLevel: [],
    protected: true,
  },
  {
    label: "About",
    route: "/settings/about",
    component: "About",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Get Help",
    route: "/settings/getHelp",
    component: "Get Help",
    roleLevel: [],
    protected: true,
  },
  {
    label: "SubAdmin",
    route: "/subAdmin",
    component: "SubAdminUserRoles",
    roleLevel: [],
    protected: true,
  },
  {
    label: "UserRoles",
    route: "/userRoles",
    component: "UserRoles",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Advice Management",
    route: "/adviceManagement",
    component: "AdviceManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Activity Cards",
    route: "/adviceManagement/activityCards/:id",
    component: "ActivityCards",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View Activity Cards",
    route: "/adviceManagement/activityCards/view/:id",
    component: "ViewActivityCards",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Activity Cards",
    route: "/adviceManagement/activityCards/edit/:id",
    component: "EditActivityCards",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Content Management",
    route: "/adviceManagement/contentManagement/:id",
    component: "ContentManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View Content Id Data",
    route: "/adviceManagement/content/view/:id",
    component: "ViewContentData",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Content Id Data",
    route: "/adviceManagement/content/edit/:id",
    component: "EditContentData",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Content Id Data",
    route: "/adviceManagement/content/add",
    component: "AddContentData",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Edit SubAdmin",
    route: "/subAdmin/addSubAdmin",
    component: "AddEditSubAdmin",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Add Edit SubAdmin",
    route: "/subAdmin/editSubAdmin/:id",
    component: "AddEditSubAdmin",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View SubAdmin",
    route: "/subAdmin/viewSubAdmin/:id",
    component: "ViewSubAdmin",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Reports",
    route: "/fengshui/reportsManagement",
    component: "Reports",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Profile Management",
    route: "/profileManagement",
    component: "ProfileManagement",
    roleLevel: [],
    protected: true,
  },
  {
    label: "View Content Id",
    route: "/fengshui/reportsManagement/viewContentId/:id",
    component: "ViewContentId",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Content Id",
    route: "/fengshui/reportsManagement/editContentId/:id",
    component: "EditContentId",
    roleLevel: [],
    protected: true,
  },
  {
    label: "Edit Profile Management",
    route: "/profileManagement/edit",
    component: "EditProfileManagement",
    roleLevel: [],
    protected: true,
  },
];
