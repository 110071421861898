import { useEffect, useRef, useState } from "react";
import Back from "../../../../shared/components/backButton/backButton";
import { REPORT_MANAGEMENT } from "../reportsManagementContants";
import style from './viewContentId.module.scss';
import SwitchButton from "../../../../shared/components/switchButton/switchButton";
import Heading from "../../../../shared/components/heading/heading";
import Button from "../../../../shared/components/button/button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { deleteReport, getReports } from "../../../../redux/AurLaVieAdmin/reports/reports.action";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../shared/components/delete/delete";
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';

const ViewContentId = () => {

    const [isTips, setIsTips] = useState(false);
    const onCategoryClick = () => setIsTips(false);
    const onTipsClick = () => setIsTips(true);
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [toastList, setToastList] = useState([]);
    const { id } = useParams();
    const languageData = useSelector(languageState);
    const [reports, setReports] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const currentDeleteRef = useRef(null);
    const [ids, setIds] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = {
            languageId: languageData?.languageId,
            reportType: isTips ? 'resident' : 'property',
            reportContentId: id
        }
        getReport(axiosInstance, params);
    }, [axiosInstance, isTips])

    const getReport = (axiosInstance, params) => {
        dispatch(getReports(axiosInstance, params)).then((response) => {
            setReports(response?.listingData);
        }).catch((error) => {
            console.log('err', error.response.data.message);
            setToastList([
                {
                    id: 0,
                    title: "Error fetching Report",
                    description: error.response.data.message,
                    icon: "error",
                },
            ]);
        });
    }

    const onDelete = () => {
        setShowDeleteModal(true);
    };

    const handleDeleteReport = (clicked) => {
        if (clicked === "Cancel") {
            setShowDeleteModal(false);
        } else {
            const data = { contentIds: [location.state.id] };
            dispatch(deleteReport(axiosInstance, data))
                .then((res) => {
                    setIds(null);
                    currentDeleteRef.current = null;
                    setToastList([
                        {
                            id: 0,
                            title: "Report Deleted",
                            description: "Report Deleted Successfully",
                            icon: "success",
                        },
                    ]);
                    setTimeout(() => {
                        setShowDeleteModal(false);
                        navigate('/fengshui/reportsManagement/');
                    }, 1000);
                })
                .catch((err) => {
                    console.log(err);
                    setToastList([
                        {
                            id: 0,
                            title: "Delete Report Error",
                            description: err?.response?.data?.message,
                            icon: "error",
                        },
                    ]);
                });
        }
    };

    const onEdit = (report) => {
        navigate(`/fengshui/reportsManagement/editContentId/${report?._id}`);
    }

    return <div className={style.container}>
        <CenterModal isOpen={showDeleteModal} onClose={() => { setShowDeleteModal(false); }} minWidth={"300px"}>
            <Delete title={REPORT_MANAGEMENT.DELETE_REPORT.TITLE} description={REPORT_MANAGEMENT.DELETE_REPORT.DESCRIPTION} onButtonClick={handleDeleteReport} />
        </CenterModal>
        <div className={style.wrapper}>
            <div className={style.back}>
                <Back title={REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.BACK.TITLE} description={REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.BACK.DESCRIPTION} />
            </div>
            <div className={style.switch}>
                <SwitchButton
                    FirstIcon={CottageOutlinedIcon}
                    SecondIcon={ApartmentOutlinedIcon}
                    firstText="Property Report"
                    secondText="Resident Report"
                    onCategoryClick={onCategoryClick}
                    onTipsClick={onTipsClick}
                    isTips={isTips}
                />
            </div>
            <div className={style.heading}>
                <Heading fontStyle={`italic`} title={`${id.substring(0, 19)}..`} description={REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.HEADING.DESCRIPTION} subtitle={' (activity)'} />
                <div className={style.action_buttons}>
                    <Button text={REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.BUTTONS.DELETE_TITLE} icon={"/icons/delete.svg"} onClick={onDelete} />
                </div>
            </div>
            {reports?.length > 0 && reports.map((report, index) => {
                return <div key={index} className={style.content}>
                    <div className={style.content_wrapper}>
                        <div className={style.img_container}>
                            <img src={report?.headImages[0]} alt="" />
                        </div>
                        <div className={style.details}>
                            <div className={style.report}>
                                <h3 className={style.name}>{report?.reportName}</h3>
                                <p className={style.description}>{report?.description}</p>
                            </div>
                            <Button icon={"/icons/edit.svg"} onClick={() => onEdit(report)}/>
                        </div>
                    </div>
                </div>
            })}
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={3500}
        />
    </div>
}

export default ViewContentId;