import React, { useEffect, useState } from "react";
import "./table.scss";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ToggleButton from "../toggleButton/toggleButton";

const Table = ({
  data,
  noDataText,
  viewNotRequired,
  editNotRequired,
  deleteNotRequired,
  onEdit,
  onDelete,
  onView,
  handleMultiple,
  totalItem,
  handlePage,
  pageNumber,
  marginTop,
  selectedRow,
  handleToggle
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeRow, setActiveRow] = useState(0);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [checkedRows, setCheckedRows] = useState([]);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalItem / itemsPerPage);
  const [currentData, setCurrentData] = useState(() =>
    data?.slice(0, itemsPerPage)
  );

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentData(data?.slice(startIndex, endIndex));
  }, [currentPage, data, itemsPerPage]);

  useEffect(() => {
    setCurrentData(data?.slice(0, itemsPerPage));
    // setCheckedRows([data && data[0]?.id])
  }, [data, itemsPerPage]);

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (handlePage) {
      handlePage(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (handleMultiple) {
      handleMultiple(checkedRows);
    }
  }, [checkedRows]);

  useEffect(() => {
    // setCheckedRows([activeRow]);
  }, [activeRow]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  const handleCheckboxChange = (event, index) => {
    if (handleMultiple) {
      handleMultiple(index);
    }
    const checked = event.target.checked;
    if (checked) {
      setCheckedRows([...checkedRows, index]); // Add checked row to checkedRows state
    } else {
      setCheckedRows(checkedRows.filter((row) => row !== index)); // Remove unchecked row from checkedRows state
    }
  };

  const handleRowSelect = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows([...selectedRows, item]);
    } else {
      setSelectedRows(selectedRows.filter((row) => row.id !== item.id));
    }
  };

  // Function to handle previous page click
  const handlePrevious = (number) => {
    setCurrentPage(currentPage - 1);
  };

  // Function to handle next page click
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  // Function to handle page number click
  const handlePageNumber = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle row click
  const handleRowClick = (event, index, row) => {
    setActiveRow(index);
    if (selectedRow && event.target.nodeName === "TD") {
      selectedRow(row);
    }
  }

  const capitalize = (str) => {
    const words = str.split(/(?=[A-Z])/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const renderColumns = (item) => {
    return Object.keys(item).map((key) =>
      key !== "id" && key !== "answer" ? (
        key === "image" ? (
          <td key={key}>
            <img
              key={item.id}
              className="user_image"
              src={item.image}
              alt={item.id}
              width={45}
            />
          </td>
        ) : key === 'featuredTip' ? <td key={key}><ToggleButton isChecked={item[key]} handleToggle={() => handleToggle(item)} /></td> : (
          key === 'status' ? <td key={key}>
            <span className={item[key] === 'active' ? `active_color_change` : `inactive_color_change`}>
              {capitalize(item[key])}
            </span></td>
            : <td key={key}>{item[key]?.length > 50 ? item[key].substring(0, 50) + '...' : item[key]}</td>
        )
      ) : null
    );
  };

  return (
    <>
      {data?.length > 0 ? (
        <div className="table_container">
          <table>
            <thead>
              <tr>
                <th>
                  {/* <input
                                type="checkbox"
                                checked={checkedRows.length === currentData.length} 
                                onChange={handleSelectAll}
                            /> */}
                  All
                </th>
                {data?.length > 0 &&
                  Object.keys(data && data[0]).map((key) =>
                    key !== "id" && key !== "answer" ? (
                      key === "image" ? (
                        <th key={key}></th>
                      ) : (
                        <th key={key}>{capitalize(key)}</th>
                      )
                    ) : null
                  )}
                {(!viewNotRequired || !editNotRequired || !deleteNotRequired) ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {currentData?.map((row, index) => (
                <tr
                  key={index}
                  className={checkedRows?.includes(row.id) ? "active" : ""}
                  onClick={(e) => handleRowClick(e, index, row)}
                >
                  <td>
                    <label className="checkbox_container">
                      <input
                        type="checkbox"
                        checked={checkedRows?.includes(row.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, row.id)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </td>
                  {renderColumns(row)}
                  <td>
                    {!viewNotRequired ? (
                      <button
                        className="action_button"
                        onClick={() => onView(row)}
                      >
                        <img src="/icons/view.svg" alt="view" width={25} />
                      </button>
                    ) : null}
                    {!editNotRequired ? (
                      <button
                        className="action_button"
                        onClick={() => onEdit(row)}
                      >
                        <img
                          src="/icons/edit.svg"
                          alt="edit"
                          width={20}
                          height={20}
                        />
                      </button>
                    ) : null}
                    {!deleteNotRequired ? (
                      <button
                        className="action_button"
                        onClick={() => onDelete(row)}
                      >
                        <img
                          src="/icons/delete.svg"
                          alt="delete"
                          width={20}
                          height={20}
                        />
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentData?.length && totalPages ? (
            <div className="pagination">
              <button
                className="previous"
                onClick={handlePrevious}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (pageNumber) => {
                  if (
                    pageNumber === 1 ||
                    pageNumber === totalPages ||
                    (pageNumber >= currentPage - 2 && pageNumber <= currentPage + 2)
                  ) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageNumber(pageNumber)}
                        className={currentPage === pageNumber ? "active" : ""}
                      >
                        {pageNumber}
                      </button>
                    );
                  } else if (
                    pageNumber === currentPage - 3 ||
                    pageNumber === currentPage + 3
                  ) {
                    return <span key={pageNumber} className={"dots"}>...</span>;
                  }
                  return null;
                }
              )}
              <button
                className="next"
                onClick={handleNext}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          ) : null}

        </div>
      ) : (
        <div>
          <p className="noData" style={{ marginTop: marginTop }}>{noDataText}</p>
        </div>
      )}
    </>
  );
};

export default Table;
