import React, { useEffect, useRef, useState } from "react";
import "./header.component.scss";
import { useDispatch, useSelector } from "react-redux";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useHandleLogout from "../../../hooks/useHandleLogout.hook";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { selectUserData } from '../../../redux/users/user.selectors'

import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  getAllLanguages,
  updateLanguage,
} from "../../../redux/AurLaVieAdmin/languages/getLanguages.action";


import useClickOutside from "../../../hooks/useClickOutside";
import { useParams } from "react-router-dom";
import Toaster from "../Toaster/Toaster.component";
import { getLanguagesAction } from "../../../redux/AurLaVieAdmin/languages/getLanguages.reducer";
const { setLanguageID } = getLanguagesAction;
// const socket = io.connect(process.env.REACT_APP_SOCKET_URL);

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const [activeLanguageId, setActiveLanguageId] = useState(
    localStorage.getItem("languageId")
  );

  const userData = useSelector(selectUserData)
  const axiosInstance = useAxiosInstance();
  const { handleLogout } = useHandleLogout();
  const [userInitial, setUserInitial] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const [options, setOptions] = useState([]);
  const active = useRef(false);
  const { isVisible, ref, setIsVisible } = useClickOutside();
  const { id } = useParams();
  const [toastList, setToastList] = useState([]);

  useEffect(() => {
    setUserDetail(userData)
  }, [userData])


  const toggleDropdown = () => {
    if (id?.length) {
      setToastList([
        {
          id: 0,
          title: "Warning!",
          description: "Please change language from listing page",
          icon: "error",
        },
      ]);
      return true
    } else {
      setIsVisible(!isVisible);

    }

  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "0",
      },

      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
        "&:hover": {
          backgroundColor: "#F4F9FF"
        }
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     socket.on(`notify_${localStorage.getItem("userId")}`, (res) => {
  //       fetchUnreadCount();
  //     });
  //   });
  // }, []);

  // const fetchUnreadCount = () => {
  //   dispatch(fetchNotificationCount(axiosInstance, {})).then((res) => {
  //     setNotificationCount(res?.data?.data?.totalCount);
  //   });
  // };

  // useEffect(() => {
  //   fetchUnreadCount();
  // }, []);

  // useEffect(() => {
  //   dispatch(fetchMyAccountUserDetail(axiosInstance)).then((res) => {
  //     setUserDetail(res?.user);
  //   });
  // }, [axiosInstance, dispatch]);



  useEffect(() => {
    const handleOptions = () => {
      dispatch(getAllLanguages(axiosInstance))
        .then((res) => {
          setOptions([
            ...options,
            {
              label: res[0].languageName,
              key: res[0]._id,
              active: false,
            },
            {
              label: res[1].languageName,
              key: res[1]._id,
              active: false,
            },
          ]);
          const languageEnglishId = res.find(lang => lang.languageCode === 'en-gb')._id
          dispatch(setLanguageID(languageEnglishId))
        })
        .catch((err) => {
          return false;
        });
    };

    !options.length && handleOptions();
  }, [axiosInstance]);

  const handleLanguageClick = (option) => {
    localStorage.setItem("languageId", option.key);
    dispatch(updateLanguage(option.key));
    setActiveLanguageId(option.key);

    setTimeout(() => {
      setIsVisible(false);
    }, 100);
  };

  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="header-logo">
          {/* <img src="/logo-one.png" className="header-logo-image" alt="logo" /> */}
        </div>
        <div className="header-icons">
          {/* <div className="header-icons-search"> */}
          {/* <AiOutlineSearch className='header-icons-search-icon'/> */}
          {/* <p>Search</p> */}
          {/* </div> */}
          {/* <div className="header-icons-help">
            <MdHelp />
            <p>Help</p>
          </div>
          <div
            className="header-icons-notifications"
            onClick={() => setNotificationToggle(true)}
            ref={notificationRef}
          >
            <IoMdNotifications />
            {notificationCount > 0 && (
              <span className="header-icons-notifications-alert">
                <p>{notificationCount}</p>
              </span>
            )}
            {notificationToggle ? (
              <NotificationMenuComponent
                setNotificationCount={setNotificationCount}
                setNotificationToggle={setNotificationToggle}
              />
            ) : (
              <></>
            )}
          </div> */}
          <div>
            <div>
              <img
                onClick={toggleDropdown}
                src="/icons/language_blue.svg"
                alt="language "
                className="language_icon"
                width={30}
              />
              <ul ref={ref} className="dropdown_list">
                {(options.length &&
                  isVisible) ?
                  options.map((option) => (
                    <li
                      onClick={() => handleLanguageClick(option)}
                      className={option.key === activeLanguageId && "active_li"}
                      key={option.key}
                    >
                      {option.label}
                    </li>
                  )) : null}
              </ul>
            </div>

            <img
              src={userData?.profilePic || localStorage.getItem('userPic') ? userData?.profilePic || localStorage.getItem('userPic') : "/icons/user_blue.svg"}
              alt="user"
              className="user_icon"
              width={40}
            />
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              //   variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon className="arrow-image" />}
            >
              <div className="welcome-user-container">
                <p className="welcome-text">Welcome</p>
                <p className="user-name">{userData?.name || localStorage.getItem('userName') ? userData?.name || localStorage.getItem('userName') : ""}</p>
              </div>
            </Button>
            <StyledMenu

              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem className="menu_item" onClick={handleClose} disableRipple>
                <AccountCircleOutlinedIcon style={{ color: "#0E4D92" }} />
                <p
                  style={{
                    fontSize: "14px",
                    color: "#0E4D92",
                    fontWeight: "500",
                  }}
                >
                  Profile
                </p>
              </MenuItem>
              <MenuItem onClick={handleLogout} disableRipple>
                <PowerSettingsNewOutlinedIcon style={{ color: "#EF7666" }} />
                <p
                  style={{
                    fontSize: "14px",
                    color: "#EF7666",
                    fontWeight: "500",
                  }}
                >
                  Logout
                </p>
              </MenuItem>
              {/* <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                <ArchiveIcon />
                Archive
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                More
              </MenuItem> */}
            </StyledMenu>
          </div>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default HeaderComponent;
