import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { MdOutlineTipsAndUpdates, MdOutlineCategory } from "react-icons/md"

// Custom Import
import './CategoryAndTipsSwitchManagement.scss'
import Toaster from "../../../../components/Global/Toaster/Toaster.component"
import SwitchButton from "../../../../shared/components/switchButton/switchButton"
import TipsManagement from "../../../../components/FengShui/TipsManagement/TipsManagement"
import CategoryManagement from "../../../../components/FengShui/CategoryManagement/CategoryManagement"

const CategoryAndTipsSwitchManagementPage = () => {
  const [isTips, setIsTips] = useState(true)
  const [toastList, setToastList] = useState([])

  const onCategoryClick = () => setIsTips(false)
  const onTipsClick = () => setIsTips(true)

  return (
    <section className="container">
        <div className="wrapper">
            <SwitchButton
                FirstIcon={MdOutlineCategory}
                SecondIcon={MdOutlineTipsAndUpdates}
                firstText="Category Management"
                secondText="Tips Management"
                onCategoryClick={onCategoryClick}
                onTipsClick={onTipsClick}
                isTips={isTips}
                />
        </div>
        {isTips ? <TipsManagement /> : <CategoryManagement />}
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
        />
    </section>
  );
};

export default CategoryAndTipsSwitchManagementPage;
