import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import Heading from "../../../../../shared/components/heading/heading";
import ImageUpload from "../../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../../shared/components/inputFields/input";
import Button from "../../../../../shared/components/button/button";
import { TextAlign } from "../../../../../shared/globalConstants/constants";
import style from "./editActivityCards.module.scss";
import buttonStyle from "../../../../../shared/components/button/button.module.scss";
import { getUserTypes } from "../../../../../redux/AurLaVieAdmin/userType/userType.action";
import { updateAdvicesActivity, addAdvices } from "../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { ADVICE_MANAGEMENT } from "../../advice.constant";
import AWS from "aws-sdk";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { useNavigate } from "react-router-dom";
import ToggleButton from "../../../../../shared/components/toggleButton/toggleButton";
import { generateUUID } from "../../../../../hooks/uniqueIdGenerator";

const EditActivityCards = ({ handleClose, payload, id, isClosed, image, title, select }) => {

  const [contentType, setContentType] = useState([
    {
      value: "Child",
      label: 'Child'
    },
    {
      value: "ContentId",
      label: 'ContentId'
    }
  ])


  const inputFields = [
    {
      type: "text",
      name: "adviceName",
      placeholder: "Activity Name",
      label: "Activity Name",
      width: "50%",
    },
    {
      type: "text",
      name: "adviceLabel",
      placeholder: "Advice Label",
      label: "Advice Label",
      width: "50%",
    },
    {
      type: "dropdown",
      name: "adviceType",
      options: contentType,
      placeholder: 'Content Type',
      label: 'Select Content Type',
      width: '50%'
    },
    {
      type: "text",
      name: "adviceOrder",
      placeholder: 'Advice Order',
      label: 'Advice Order',
      width: '50%'
    },
    {
      type: "textarea",
      name: "description",
      placeholder: "Content",
      label: "Content",
      width: "100%",
    },
  ];




  const [formData, setFormData] = useState({});
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState([]);
  const [activityImage, setActivityImage] = useState();
  const [removeImage, setRemoveImage] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (select === "Edit") {
      console.log('inside edit', payload, image)
      setFormData(payload)
      setPreviewImage(image)
    }
  }, [select]);

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  AWS.config.update({
    accessKeyId: "AKIA24FEBSB6D3SDB5XV",
    secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: "aurlavie" },
    region: "us-east-1",
  });

  const uploadToS3 = async (file, name) => {
    const id = generateUUID()
    const fileKey = `activity-${id}`;
    const params = {
      Body: file,
      Bucket: "aurlavie",
      Key: fileKey,
    };
    try {
      dispatch(isLoading(true));
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        handleUpdate(upload.Location);
      }
    } catch (error) {
      console.log(error);
      setToastList([
        {
          id: 0,
          title: "Error",
          description: "Error while updating the activity.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleUpdate = (imageUrl, option) => {
    const languageId = localStorage.getItem("languageId");
    const appId = localStorage.getItem("appId");

    formData["languageId"] = languageId;
    formData["appId"] = appId;
    formData["parentId"] = payload.parentId;

    if (formData?.adviceType?.value) {
      formData["adviceType"] = formData?.adviceType?.value;
    } else {
      formData["adviceType"] = payload?.adviceType;
    }

    formData["created_by"] = localStorage.getItem("userId");
    formData["modified_by"] = localStorage.getItem("userId");

    if (select === "Edit") {
      if (imageUrl) {
        formData['mainImgPath'] = imageUrl;
      } else {
        formData['mainImgPath'] = option === "blanl" ? null : activityImage || image;
      }

      dispatch(updateAdvicesActivity(axiosInstance, formData, { id }))
        .then((res) => {
          setToastList([
            {
              id: 0,
              title: "Activity Updated Successfully",
              description: res.message,
              icon: "success",
            },
          ]);

          setTimeout(() => {
            setRemoveImage(true);
            setFormData({});
            handleClose();
          }, 500);
        })
        .catch((err) => {
          console.log(err, "err=========>")
          setToastList([
            {
              id: 0,
              title: "Updating Card Failed",
              description: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }

    else if (select === "Add") {
      if (imageUrl) {
        formData['mainImgPath'] = imageUrl;
      } else {
        delete formData.imageUrl;
      }
      formData["adviceLabel"] = formData.adviceName.replaceAll(" ", "-");
      dispatch(addAdvices(axiosInstance, formData))
        .then((res) => {
          setToastList([
            {
              id: 0,
              title: "Activity Added Successfully",
              description: res.message,
              icon: "success",
            },
          ]);

          setTimeout(() => {
            setRemoveImage(true);
            setFormData({});
            handleClose();
          }, 500);
        })
        .catch((err) => {
          console.log(err, "err=========>")
          setToastList([
            {
              id: 0,
              title: "Adding Card Failed",
              description: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }





  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (activityImage?.name) {
      uploadToS3(activityImage, activityImage.name);
    }
    else if (activityImage === " ") {
      handleUpdate(null, "blank")
    }
    else {
      handleUpdate();
    }
  };

  const handleFile = (url) => {

    if (url) {
      setActivityImage(url);
    }
  };



  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.heading}>
          <Heading
            title={title}
            description={ADVICE_MANAGEMENT.EDIT_ACTIVITY_CARD.HEADING.DESCRIPTION}
            textAlign={TextAlign.CENTER}
          />
        </div>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              select={select}
              id={select === "edit" ? "editMainImage" : "addMainImage"}
              width={`180px`}
              height={`180px`}
              previewImage={previewImage}
              handleFile={handleFile}
              title={ADVICE_MANAGEMENT.EDIT_ACTIVITY_CARD.IMAGE.TITLE}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  rows={5}
                  type={inputField.type}
                  label={inputField.label}
                  value={formData[inputField.name]}
                  placeholder={inputField.name === "adviceType" && select === "Edit" ? formData[inputField.name] : inputField.placeholder}
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                />
              </div>
            ))}
            <div className={style.action_button}>
              <Button
                text={title}
                buttonType={`${buttonStyle.button_primary}`}
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default EditActivityCards;


