import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Heading from "../../../../../shared/components/heading/heading";
import ImageUpload from "../../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../../shared/components/inputFields/input";
import Button from "../../../../../shared/components/button/button";
import style from "./viewActivityCards.module.scss";
import { ADVICE_MANAGEMENT } from "../../advice.constant";
import AWS from "aws-sdk";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../../../../../shared/components/backButton/backButton";
import CenterModal from "../../../../../shared/components/modal/centeredModal/centeredModal";
import EditActivityCards from "../editActivityCards/editActivityCards";
import { viewAdvice } from "../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";



const ViewActivityCards = () => {
  const { id } = useParams();
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [formData, setFormData] = useState({});
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState([]);
  const [activityImage, setActivityImage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsClosed(!showEditActivity)
  }, [showEditActivity])

  const inputFields = [
    {
      type: "text",
      name: "adviceName",
      placeholder: "Activity Name",
      label: "Activity Name",
      width: "100%",
    },
    {
      type: "text",
      name: "adviceLabel",
      placeholder: "Advice Label",
      label: "Advice Label",
      width: "100%",
    },
    {
      type: "text",
      name: "adviceOrder",
      placeholder: 'Advice Order',
      label: 'Advice Order',
      width: '100%'
    },
    {
      type: "textarea",
      name: "description",
      placeholder: "Content",
      label: "Content",
      width: "100%",
    },
  ];

  useEffect(() => {

    getActivityCardInfo(true)

  }, [axiosInstance])

  const getActivityCardInfo = (check) => {
    const params = {
      id
    }

    dispatch(viewAdvice(axiosInstance, params)).then(res => {
      const response = {
        adviceName: res.adviceName,
        description: res.description,
        adviceLabel: res.adviceLabel,
        adviceOrder: res.adviceOrder,
        adviceType: res.adviceType,
        parentId: res.parentId
      }

      if (res.mainImgPath && res.mainImgPath !== "string") {
        setActivityImage(res.mainImgPath)
      }

      setFormData(response);

      if (location.state.openModal && check) {
        setShowEditActivity(true);
      }

    }).catch(err => {
      console.log(err, "error")
      setToastList([
        {
          id: 0,
          title: "Fething Advice info Failed",
          description: err?.response?.data?.message,
          icon: "error",
        },
      ]);
    })

  }
  const handleSubmit = (event) => {
    return true
  };


  const handleClose = (data) => {
    setShowEditActivity(false);
    getActivityCardInfo();
  };

  const closeModal = () => {

    getActivityCardInfo();
    setShowEditActivity(false);
  };

  const editActivityById = (event) => {
    navigate(`/adviceManagement/activityCards/edit/${id}`)
  }

  const handleModal = () => {
    setShowEditActivity(true)
  }

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal isOpen={showEditActivity} onClose={closeModal}>
          <EditActivityCards
            title={ADVICE_MANAGEMENT.EDIT_ACTIVITY_CARD.HEADING.TITLE}
            isClosed={isClosed}
            payload={formData}
            image={activityImage}
            id={id}
            handleClose={handleClose}
            select={showEditActivity ? "Edit" : null}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={ADVICE_MANAGEMENT.VIEW_ACTIVITY_CARD.BACK.DESCRIPTION}
            description={
              ADVICE_MANAGEMENT.VIEW_ACTIVITY_CARD.BACK.TITLE
            }
          />
        </div>
        <div className={style.heading}>
          <Heading
            fontStyle={`italic`}
            title={location.state.title}
            description={
              ADVICE_MANAGEMENT.VIEW_ACTIVITY_CARD.HEADING.DESCRIPTION
            }
            subtitle={' (activity)'}
          />
          <div className={style.action_buttons}>
            <Button
              text={
                ADVICE_MANAGEMENT.VIEW_ACTIVITY_CARD.BUTTONS.EDIT.TITLE
              }
              icon={"/icons/edit.svg"}
              onClick={handleModal}
            />
            {/* <Button
              text={ADVICE_MANAGEMENT.VIEW_ACTIVITY_CARD.BUTTONS.DELETE.TITLE}
              icon={"/icons/delete.svg"}
            // onClick={deleteNotificationModalHandler}
            /> */}
          </div>
        </div>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              isDisabled={true}
              previewImage={activityImage}
              height="120px"
              width="120px"
              title={ADVICE_MANAGEMENT.VIEW_ACTIVITY_CARD.IMAGE.TITLE}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  isDisabled={true}
                  rows={4}
                  type={inputField.type}
                  label={inputField.label}
                  value={formData[inputField.name]}
                  placeholder={inputField.placeholder}
                  options={inputField.options}
                />
              </div>
            ))}
            <div className={style.input_field}>
              <label className={style.label}>Content Type</label>
              <div className={style.content_container}>
                {formData[`adviceType`]}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default ViewActivityCards;
