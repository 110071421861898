import { useEffect, useRef, useState } from "react";
import Heading from "../../../../../shared/components/heading/heading";
import Table from "../../../../../shared/components/table/table";
import { ADVICE_MANAGEMENT } from "../../advice.constant";
import style from "./contentManagement.module.scss";
import Input from "../../../../../shared/components/inputFields/input";
import { AppType } from "../../../../../shared/globalConstants/constants";
import Button from "../../../../../shared/components/button/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector, } from "react-redux";
import {
    getContentIdsListing
} from "../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { languageState } from "../../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

import { selectUsersListingData } from "../../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.selectors";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import moment from "moment";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import CenterModal from "../../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../../shared/components/delete/delete";
import { useParams } from "react-router-dom";
import Back from "../../../../../shared/components/backButton/backButton";
const AdviceManagment = () => {
    const [contents, setContents] = useState([]);
    const [selectedApplicationType, setSelectedApplicationType] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allUsersdaa = useSelector(selectUsersListingData);
    const languageData = useSelector(languageState);
    const axiosInstance = useAxiosInstance();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [toastList, setToastList] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const currentDeleteRef = useRef(null);
    const currentAdviceRef = useRef(null);
    const { id } = useParams();
    const location = useLocation();

    console.log(location.state, "state data=======>")


    const [ids, setIds] = useState(null);

    const [appId, setAppId] = useState(localStorage.getItem("appId"));
    const [languageId, setLanguageId] = useState(
        localStorage.getItem("languageId")
    );

    const handleContents = () => {
        currentDeleteRef.current = null;
        setShowDeleteModal(false);
        const params = {
            page,
            limit: 10,
        };

        const payload = {
            appId,
            languageId,
            adviceId: id
        };
        getContentListing(axiosInstance, payload, params);
    };

    useEffect(() => {
        if (languageData.languageId) {
            const params = {
                page: 1,
                limit: 10,
            };

            const payload = {
                appId,
                languageId: languageData.languageId,
                adviceId: id
            };
            getContentListing(axiosInstance, payload, params);
        }
    }, [languageData]);

    useEffect(() => {
        handleContents();
    }, [page]);

    useEffect(() => {
        setPage(1);
        let timerId;
        const fetchResults = async () => {
            try {
                const params = {
                    page: 1,
                    limit: pageSize,
                };

                const payload = {
                    appId,
                    languageId,
                    keyword: searchTerm,
                    adviceId: id
                };

                getContentListing(axiosInstance, payload, params);
            } catch (error) {
                console.log(error);
            }
        };

        if (searchTerm || searchTerm === "") {
            timerId = setTimeout(() => {
                fetchResults();
            }, 500);
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [searchTerm]);

    const getContentListing = (axiosInstance, payload, params) => {
        dispatch(getContentIdsListing(axiosInstance, payload, params))
            .then((response) => {
                currentAdviceRef.current = response.listingData;
                const updatedContents = response?.listingData.map(
                    (data, index) => {
                        const newIndex = ((page - 1) * pageSize) + index + 1;
                        return {
                            id: data?._id,
                            "S. No": newIndex > 100
                                ? newIndex
                                : newIndex < 100 && newIndex > 9
                                    ? `0${newIndex}`
                                    : `00${newIndex}`,
                            "Content Id": data?.contentId,
                            "Created on": moment(data?.created_at).format("MM/DD/YYYY"),
                        };
                    }
                );

                setContents(updatedContents);
                setTotalItem(response?.meta?.total);
            })
            .catch((err) => {
                console.log(err)
                // setToastList([
                //     {
                //         id: 0,
                //         title: "Fething Content Failed",
                //         description: err?.response?.data?.message,
                //         icon: "error",
                //     },
                // ]);
            });
    };

    const onSearch = (event) => {
        setSearchTerm(event);
    };

    const onEdit = (data) => {
        navigate(`/adviceManagement/content/edit/${data.id}`);
    };

    const onDelete = (data) => {
        // currentDeleteRef.current = [data.id];
        // setShowDeleteModal(true);
    };

    const onView = (event) => {
        navigate(`/adviceManagement/content/view/${event.id}`);
    };

    const handleMultiple = (event) => {
        setIds(event);
    };

    const handlePage = (page) => {
        setPage(page);
    };

    const deletemany = () => {
        if (ids.length) {
            setShowDeleteModal(true);
        }
    };
    const addContent = () => {
        navigate(`/adviceManagement/content/add`, {
            state: { data: location.state.data }
        });
    };

    const selectedRow = (row) => {


        const filteredAdvice = currentAdviceRef.current.filter(element => element._id === row.id)



        if (filteredAdvice[0].adviceType === "Child") {
            navigate(`/adviceManagement/activityCards/${row.id}`, {
                state: { title: filteredAdvice[0].adviceName }
            });
        }


    }

    const handleDeleteNotification = (clicked) => {
        if (clicked === "Cancel") {
            setShowDeleteModal(false);
            currentDeleteRef.current = null;
        } else {
            const data = currentDeleteRef.current?.length
                ? { ids: currentDeleteRef.current }
                : { ids };

            // dispatch(deleteNotification(axiosInstance, data))
            //     .then((res) => {
            //         setToastList([
            //             {
            //                 id: 0,
            //                 title: "Notifications Deleted Successfully",
            //                 description: res.message,
            //                 icon: "success",
            //             },
            //         ]);
            //         setTimeout(() => {
            //             handleContents();
            //         }, 1000);
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //         setToastList([
            //             {
            //                 id: 0,
            //                 title: "Delete Notification Error",
            //                 description: err?.response?.data?.message,
            //                 icon: "error",
            //             },
            //         ]);
            //     });
        }
    };

    return (
        <section className={style.container}>
            <div className={style.wrapper}>
                {/* <CenterModal
                    isOpen={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                    }}
                    minWidth={"300px"}
                >
                    <Delete
                        title={ADVICE_MANAGEMENT.DELETE_NOTIFICATION.TITLE}
                        description={
                            ADVICE_MANAGEMENT.DELETE_NOTIFICATION.DESCRIPTION
                        }
                        onButtonClick={handleDeleteNotification}
                    />
                </CenterModal> */}
                <div className={style.back}>
                    <Back
                        title={ADVICE_MANAGEMENT.CONTENT_LISTING.BACK.DESCRIPTION}
                        description={ADVICE_MANAGEMENT.CONTENT_LISTING.BACK.TITLE}
                    />
                </div>

                <section className={style.title_filters}>
                    <div className={style.heading}>
                        <Heading
                            fontStyle={`italic`}
                            title={`${id.substring(0, 12)}..`}
                            subtitle={` (Return Id)`}
                            description={ADVICE_MANAGEMENT.HEADING.DESCRIPTION}
                        />
                    </div>
                    <div className={style.filters}>
                        <Button text={ADVICE_MANAGEMENT.CONTENT_LISTING.BUTTONS.ADD.TITLE} icon={"/icons/add.svg"} onClick={addContent} />
                        {/* <Button icon={"/icons/delete.svg"} onClick={deletemany} /> */}
                    </div>
                </section>
                <section className={style.listing}>
                    <Table
                        noDataText={"No Content IDs found"}
                        handleMultiple={handleMultiple}
                        data={contents}
                        onView={(event) => onView(event)}
                        onEdit={(event) => onEdit(event)}
                        // onDelete={onDelete}
                        totalItem={totalItem}
                        handlePage={handlePage}
                        pageNumber={page}
                        deleteNotRequired={true}
                    />
                </section>
            </div>
            <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={2500}
            />
        </section>
    );
};

export default AdviceManagment;
